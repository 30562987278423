.word-knowledge-page {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
}

.page-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  background: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.2s ease;
}

.back-button:hover {
  transform: translateX(-2px);
  border-color: var(--primary-color);
}

.page-header h1 {
  color: var(--primary-color);
  font-size: 2rem;
  margin: 0;
}

.quiz-setup-container {
  background: var(--card-background);
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 20px var(--shadow-color);
  border: 1px solid var(--border-color);
}

.setup-section {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid var(--border-color);
}

.setup-section:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.setup-section h2 {
  color: var(--text-color);
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.random-button {
  background: var(--input-background);
  border: 1px solid var(--border-color);
  color: var(--text-color);
  padding: 0.5rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.random-button:hover {
  transform: rotate(180deg);
  border-color: var(--primary-color);
}

.word-count-slider {
  margin-bottom: 1rem;
}

.word-count-slider label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--text-secondary);
}

.word-count {
  color: var(--primary-color);
  font-weight: 600;
}

.custom-slider {
  width: 100%;
  margin: 1rem 0;
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.available-words {
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.alphabet-buttons {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.alphabet-button {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  background: var(--input-background);
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.alphabet-button.selected {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: black;
  transform: scale(1.1);
}

.alphabet-button:hover:not(.selected) {
  border-color: var(--primary-color);
  transform: translateY(-2px);
}

.selected-letters {
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin-top: 1rem;
}

.start-quiz-button {
  width: 100%;
  padding: 1rem;
  background: var(--primary-color);
  border: none;
  border-radius: 8px;
  color: black;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 2rem;
}

.start-quiz-button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 210, 255, 0.3);
}

.start-quiz-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: var(--border-color);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .word-knowledge-page {
    padding: 1rem;
    margin: 1rem;
  }

  .quiz-setup-container {
    padding: 1.5rem;
  }

  .page-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .page-header h1 {
    font-size: 1.75rem;
  }

  .alphabet-buttons {
    grid-template-columns: repeat(auto-fill, minmax(36px, 1fr));
  }

  .alphabet-button {
    width: 36px;
    height: 36px;
    font-size: 0.9rem;
  }
}

/* Update stats section styles */
.stats-section {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid var(--border-color);
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.stat-item {
  background: var(--card-background);
  padding: 1.25rem;
  border-radius: 12px;
  border: 1px solid var(--border-color);
  transition: all 0.2s ease;
}

.stat-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px var(--shadow-color);
}

.stat-content {
  width: 100%;
}

.stat-main {
  margin-bottom: 0.75rem;
}

.stat-value {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--text-color);
  line-height: 1;
  margin-bottom: 0.25rem;
}

.stat-label {
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.stat-progress {
  width: 100%;
}

.progress-bar {
  width: 100%;
  height: 6px;
  background: var(--input-background);
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

.progress-fill {
  height: 100%;
  background: var(--secondary-color);
  transition: width 0.3s ease;
}

.progress-text {
  font-size: 0.8rem;
  color: var(--text-secondary);
  text-align: right;
}

/* Update mobile styles */
@media (max-width: 768px) {
  .stats-grid {
    grid-template-columns: 1fr;
  }

  .stat-item {
    padding: 1rem;
  }

  .stat-value {
    font-size: 1.25rem;
  }
}
