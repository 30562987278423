@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212;
  color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  color: #00d2ff;
}

/* Scrollbar Styles */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #121212;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: #333333;
  border-radius: 6px;
  border: 3px solid #121212;
}

::-webkit-scrollbar-thumb:hover {
  background: #404040;
}

/* Selection Style */
::selection {
  background-color: rgba(0, 210, 255, 0.3);
  color: #ffffff;
}
