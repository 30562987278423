.calculator-card {
  background-color: var(--card-background);
  border-radius: 12px;
  padding: 2rem;
  border: 1px solid var(--border-color);
}

.calculator-header {
  margin-bottom: 2rem;
  text-align: center;
}

.calculator-header h2 {
  color: var(--text-color);
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.calculator-description {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.calculator-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.unit-selectors {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.select-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.select-group label {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.select-group select {
  padding: 0.75rem;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  background-color: var(--input-background);
  color: var(--text-color);
  width: 100%;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input-group label {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.input-group input {
  padding: 0.75rem;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  background-color: var(--input-background);
  color: var(--text-color);
  width: 100%;
}

.calculator-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.convert-button,
.clear-button {
  padding: 0.75rem;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.convert-button {
  background-color: var(--primary-color);
  color: black;
  border: none;
}

.convert-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.clear-button {
  background-color: var(--input-background);
  color: var(--text-color);
  border: 1px solid var(--border-color);
}

.result {
  text-align: center;
  padding: 1rem;
  background-color: var(--input-background);
  border-radius: 8px;
  color: var(--primary-color);
  font-weight: 600;
  font-size: 1.1rem;
}

.no-data {
  text-align: center;
  color: var(--text-secondary);
}

@media (max-width: 480px) {
  .calculator-card {
    padding: 1rem;
  }

  .unit-selectors {
    grid-template-columns: 1fr;
  }

  .calculator-buttons {
    grid-template-columns: 1fr;
  }
} 