.flashcards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem;
  min-height: 400px;
}

.card-progress {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.flashcard {
  width: 300px;
  height: 200px;
  perspective: 1000px;
  cursor: pointer;
  margin: 2rem 0;
}

.flashcard-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flashcard.flipped .flashcard-inner {
  transform: rotateY(180deg);
}

.flashcard-front,
.flashcard-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  border-radius: 12px;
  background-color: var(--card-background);
  border: 1px solid var(--border-color);
  font-size: 1.2rem;
  color: var(--text-color);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.flashcard-back {
  transform: rotateY(180deg);
  background-color: var(--primary-color);
  color: black;
}

.flashcard-controls {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;
  max-width: 300px;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.control-button {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  background-color: var(--input-background);
  border: 1px solid var(--border-color);
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.2s ease;
  flex: 1;
}

.control-button:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: black;
}

.no-cards {
  color: var(--text-secondary);
  text-align: center;
  padding: 2rem;
}

@media (max-width: 480px) {
  .flashcards-container {
    min-height: 350px;
  }

  .flashcard {
    width: 260px;
    height: 180px;
    margin: 1.5rem 0;
  }

  .flashcard-front,
  .flashcard-back {
    font-size: 1rem;
    padding: 1rem;
  }

  .flashcard-controls {
    max-width: 260px;
  }

  .control-button {
    padding: 0.6rem 1rem;
    font-size: 0.9rem;
  }
} 