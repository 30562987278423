.vocabulary-page {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
}

.vocabulary-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.vocabulary-card {
  background: var(--card-background);
  border-radius: 12px;
  padding: 1.25rem;
  border: 1px solid var(--border-color);
  transition: all 0.2s ease;
}

.word-header {
  cursor: pointer;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
}

.word-header h2 {
  color: var(--primary-color);
  margin: 0;
  font-size: 1.5rem;
}

.mastery-badge {
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 600;
}

.word-definition {
  color: var(--text-secondary);
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  padding: 1rem;
  background: var(--input-background);
  border-radius: 8px;
  border: 1px solid var(--border-color);
}

.synonyms-container h3 {
  color: var(--text-color);
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.synonyms-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.synonym-tag {
  padding: 0.5rem 1rem;
  background: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: 20px;
  font-size: 0.95rem;
  color: var(--text-color);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.synonym-tag.mastered {
  background: rgba(74, 222, 128, 0.1);
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}

.mastery-icon {
  font-size: 0.8rem;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .vocabulary-page {
    padding: 1rem;
    margin: 1rem;
  }

  .vocabulary-card {
    padding: 1.25rem;
  }

  .word-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
  }

  .word-definition {
    font-size: 0.95rem;
    padding: 0.875rem;
  }

  .synonyms-grid {
    gap: 0.5rem;
  }

  .synonym-tag {
    padding: 0.4rem 0.875rem;
    font-size: 0.9rem;
  }
}

/* Add these styles to the existing VocabularyPage.css */

.search-container {
  margin-bottom: 2rem;
  padding: 1.25rem;
  background: var(--card-background);
  border-radius: 12px;
  border: 1px solid var(--border-color);
  width: 100%;
  box-sizing: border-box;
}

.search-input-wrapper {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
}

.search-input {
  width: 100%;
  padding: 1rem 2.5rem 1rem 1rem;
  background: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-color);
  font-size: 1rem;
  transition: all 0.2s ease;
  box-sizing: border-box;
}

.search-input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(0, 210, 255, 0.1);
}

.clear-search {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: var(--text-secondary);
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.25rem;
  line-height: 1;
  opacity: 0.7;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  min-height: 24px;
}

.clear-search:hover {
  opacity: 1;
  color: var(--error-color);
}

.search-results-count {
  margin-top: 0.75rem;
  color: var(--text-secondary);
  font-size: 0.9rem;
  text-align: right;
  padding: 0 0.25rem;
}

.no-results {
  text-align: center;
  padding: 3rem 1rem;
  color: var(--text-secondary);
  background: var(--card-background);
  border-radius: 12px;
  border: 1px solid var(--border-color);
}

.no-results p {
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.clear-search-button {
  padding: 0.75rem 1.5rem;
  background: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-color);
  font-size: 0.95rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.clear-search-button:hover {
  background: var(--border-color);
  transform: translateY(-2px);
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .search-container {
    margin: 1rem;
    padding: 1rem;
  }

  .search-input-wrapper {
    max-width: 100%;
  }

  .search-input {
    padding: 0.875rem 2.25rem 0.875rem 0.875rem;
    font-size: 0.95rem;
  }

  .clear-search {
    right: 0.5rem;
    font-size: 1.25rem;
    min-width: 20px;
    min-height: 20px;
  }

  .search-results-count {
    margin-top: 0.5rem;
    font-size: 0.85rem;
  }
}

/* Small screen optimizations */
@media screen and (max-width: 380px) {
  .search-container {
    margin: 0.75rem;
    padding: 0.875rem;
  }

  .search-input {
    padding: 0.75rem 2rem 0.75rem 0.75rem;
    font-size: 0.9rem;
  }

  .clear-search {
    right: 0.4rem;
    font-size: 1.1rem;
    min-width: 18px;
    min-height: 18px;
  }
}

/* Add these styles to your existing VocabularyPage.css */

.search-and-filter-container {
  background: var(--card-background);
  border-radius: 12px;
  border: 1px solid var(--border-color);
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.filter-container {
  display: flex;
  gap: 1.5rem;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--border-color);
}

.filter-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
  position: relative;
}

.filter-group label {
  color: var(--text-secondary);
  font-size: 0.9rem;
  font-weight: 600;
}

.filter-group select {
  padding: 0.75rem;
  background: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-color);
  font-size: 0.95rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.filter-group select:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(0, 210, 255, 0.1);
}

.filter-group select:hover {
  border-color: var(--primary-color);
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .search-and-filter-container {
    margin: 1rem;
    padding: 1rem;
  }

  .filter-container {
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
    padding-top: 1rem;
  }

  .filter-group {
    width: 100%;
  }

  .filter-group select {
    padding: 0.75rem;
    font-size: 0.9rem;
  }
}

/* Small screen optimizations */
@media screen and (max-width: 380px) {
  .search-and-filter-container {
    margin: 0.75rem;
    padding: 0.875rem;
  }

  .filter-container {
    gap: 0.875rem;
    margin-top: 0.875rem;
    padding-top: 0.875rem;
  }

  .filter-group select {
    padding: 0.625rem;
    font-size: 0.875rem;
  }
}

/* Add these styles to your existing VocabularyPage.css */

.dropdown-filter {
  position: relative;
}

.dropdown-trigger {
  width: 100%;
  padding: 0.75rem;
  background: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-color);
  font-size: 0.95rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-trigger:hover {
  border-color: var(--primary-color);
}

.dropdown-trigger .arrow {
  font-size: 0.8rem;
  transition: transform 0.2s ease;
}

.dropdown-trigger .arrow.up {
  transform: rotate(180deg);
}

.letter-filter-dropdown {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  background: var(--card-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 1rem;
  z-index: 100;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.letter-buttons {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(36px, 1fr));
  gap: 0.4rem;
  max-height: 200px;
  overflow-y: auto;
}

.letter-button {
  width: 36px;
  height: 36px;
  padding: 0;
  border-radius: 8px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--input-background);
  color: var(--text-color);
  border: 2px solid var(--border-color);
  transition: all 0.2s ease;
  cursor: pointer;
}

.letter-button.selected {
  background-color: var(--primary-color);
  color: black;
  border-color: var(--primary-color);
  transform: scale(1.1);
  box-shadow: 0 2px 8px rgba(0, 210, 255, 0.3);
}

.letter-button:hover:not(.selected) {
  background-color: var(--border-color);
  transform: translateY(-2px);
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .letter-filter-dropdown {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 12px 12px 0 0;
    padding: 1.5rem;
    max-height: 50vh;
    overflow-y: auto;
  }

  .letter-buttons {
    grid-template-columns: repeat(auto-fill, minmax(32px, 1fr));
  }

  .letter-button {
    width: 32px;
    height: 32px;
    font-size: 0.8rem;
  }

  /* Add overlay for mobile */
  .dropdown-filter::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 90;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease;
  }

  .dropdown-filter.active::before {
    opacity: 1;
    pointer-events: auto;
  }
}

/* Small screen optimizations */
@media screen and (max-width: 380px) {
  .letter-buttons {
    grid-template-columns: repeat(auto-fill, minmax(28px, 1fr));
    gap: 0.3rem;
  }

  .letter-button {
    width: 28px;
    height: 28px;
    font-size: 0.75rem;
  }
}

/* Add these styles to your existing VocabularyPage.css */

.word-title-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.media-icons {
  display: flex;
  gap: 0.5rem;
}

.media-icon {
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  color: var(--text-secondary);
  opacity: 0.5;
  transition: all 0.2s ease;
  font-size: 1.2rem;
}

.media-icon.active {
  opacity: 1;
  color: var(--primary-color);
}

.media-icon:hover.active {
  transform: scale(1.1);
}

.media-icon:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .word-title-container {
    width: 100%;
    justify-content: space-between;
  }

  .media-icons {
    gap: 0.25rem;
  }

  .media-icon {
    padding: 0.4rem;
    font-size: 1.1rem;
  }
}

/* Media Popup Styles */
.media-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 2rem;
}

.media-popup-content {
  position: relative;
  max-width: 90vw;
  max-height: 90vh;
  background: var(--card-background);
  border-radius: 12px;
  padding: 1rem;
}

.close-popup {
  position: absolute;
  top: -1.5rem;
  right: -1.5rem;
  background: var(--card-background);
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--text-color);
  transition: all 0.2s ease;
}

.close-popup:hover {
  transform: scale(1.1);
  background: var(--error-color);
}

.media-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.media-container img,
.media-container video {
  max-width: 100%;
  max-height: 70vh;
  border-radius: 8px;
}

.carousel-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.carousel-button {
  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.carousel-button:hover {
  color: var(--primary-color);
  transform: scale(1.1);
}

.carousel-counter {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .media-popup-overlay {
    padding: 1rem;
  }

  .close-popup {
    top: 0.5rem;
    right: 0.5rem;
  }

  .media-container img,
  .media-container video {
    max-height: 60vh;
  }
}

.edit-controls {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  background: var(--card-background);
  border-radius: 12px;
  border: 1px solid var(--border-color);
}

.mastery-mode-button {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  background: var(--card-background);
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.mastery-mode-button.active {
  background: var(--secondary-color);
  color: black;
}

.master-word-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-secondary);
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.95rem;
}

.master-word-button:hover {
  transform: translateY(-2px);
  border-color: var(--secondary-color);
  background: rgba(74, 222, 128, 0.1);
}

.master-word-button.mastered {
  background: rgba(74, 222, 128, 0.1);
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}

.master-icon {
  font-size: 1.2rem;
}

.master-text {
  font-weight: 500;
}

.card-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .card-actions {
    flex-direction: column;
    align-items: stretch;
    gap: 0.75rem;
  }

  .master-word-button {
    justify-content: center;
  }
}

/* Make mastery mode button more prominent */
.mastery-mode-button {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  background: var(--card-background);
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
}

.mastery-mode-button:hover {
  transform: translateY(-2px);
  border-color: var(--secondary-color);
  background: rgba(74, 222, 128, 0.1);
}

.mastery-mode-button.active {
  background: var(--secondary-color);
  color: black;
  border-color: var(--secondary-color);
}

.mastery-icon {
  font-size: 1.2rem;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .card-actions {
    flex-direction: column;
    align-items: stretch;
    gap: 0.75rem;
  }

  .master-word-button {
    justify-content: center;
  }
}

.synonym-tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

/* Update existing mastered style */
.synonym-tag.mastered {
  background: rgba(74, 222, 128, 0.1);
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}

/* When a synonym is both mastered and flagged, flagged takes precedence */
.synonym-tag.mastered.flagged {
  background: rgba(255, 215, 0, 0.15);
  border-color: #ffd700;
  color: #ffd700;
}

.vocabulary-card.has-flagged {
  border-color: #ffd700;
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.2);
}

.vocabulary-card.has-flagged:hover {
  box-shadow: 0 0 15px rgba(255, 215, 0, 0.3);
}

.vocabulary-card.has-flagged::before {
  content: '⚠️';
  position: absolute;
  top: -8px;
  right: -8px;
  background: #ffd700;
  color: black;
  padding: 4px;
  border-radius: 50%;
  font-size: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Update existing styles */
.filter-group select {
  min-width: 150px; /* Ensure enough space for new option */
}

/* Enhance visibility of flagged items when using flagged sort */
.vocabulary-card.has-flagged .synonyms-container {
  border-color: rgba(255, 215, 0, 0.3);
}

.synonym-tag.flagged {
  animation: flaggedPulse 2s infinite;
}

@keyframes flaggedPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0.4);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(255, 215, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0);
  }
}

/* Add to existing styles */

.flag-synonym-button {
  background: none;
  border: none;
  padding: 0.25rem;
  margin-left: 0.5rem;
  cursor: pointer;
  color: var(--text-secondary);
  opacity: 0.5;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
}

.flag-synonym-button:hover {
  opacity: 1;
  transform: scale(1.1);
}

.flag-synonym-button .active {
  color: #ffd700;
  opacity: 1;
}

.synonym-tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.synonym-tag.flagged {
  background: rgba(255, 215, 0, 0.15);
  border-color: #ffd700;
  color: #ffd700;
  animation: flagPulse 2s infinite;
}

@keyframes flagPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0.4);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(255, 215, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0);
  }
}

/* When a synonym is both mastered and flagged, flagged takes precedence */
.synonym-tag.mastered.flagged {
  background: rgba(255, 215, 0, 0.15);
  border-color: #ffd700;
  color: #ffd700;
}

.synonym-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.pronunciation-bar {
  height: 3px;
  background: var(--border-color);
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  padding: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.pronunciation-bar::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--primary-color);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.pronunciation-bar:hover {
  background: var(--border-color);
}

.pronunciation-bar:hover::before {
  transform: scaleX(1);
}

.pronunciation-bar.playing::before {
  transform: scaleX(1);
  animation: playProgress 1s linear;
}

@keyframes playProgress {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

/* Update the synonym-tag styles to work with the new container */
.synonym-tag {
  width: 100%;
  box-sizing: border-box;
}

/* Add these styles to your existing VocabularyPage.css */

.word-definition-popup {
  position: fixed;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1001;
  background: var(--card-background);
  border-radius: 16px;
  border: 1px solid var(--border-color);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  width: 380px;
  max-width: 90vw;
  pointer-events: auto;
  backdrop-filter: blur(8px);
  overflow: hidden;
}

.popup-content {
  padding: 1.5rem;
  max-height: 70vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) var(--card-background);
}

.popup-content::-webkit-scrollbar {
  width: 6px;
}

.popup-content::-webkit-scrollbar-track {
  background: var(--card-background);
  border-radius: 3px;
}

.popup-content::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 3px;
}

.word-header {
  position: relative;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--border-color);
}

.word-header h2 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.phonetic {
  color: var(--text-secondary);
  font-size: 1rem;
  font-style: italic;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.meaning-section {
  margin-bottom: 2rem;
}

.meaning-section:last-child {
  margin-bottom: 0;
}

.part-of-speech {
  color: var(--text-color);
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  background: var(--input-background);
  border-radius: 8px;
  display: inline-block;
}

.definitions-list {
  list-style: none;
  counter-reset: definition-counter;
  padding: 0;
}

.definition-item {
  position: relative;
  margin-bottom: 1.25rem;
  padding-left: 2rem;
  counter-increment: definition-counter;
}

.definition-item:last-child {
  margin-bottom: 0;
}



.definition {
  color: var(--text-color);
  line-height: 1.6;
  margin-bottom: 0.5rem;
}

.example {
  color: var(--text-secondary);
  font-style: italic;
  font-size: 0.95rem;
  line-height: 1.5;
  padding: 0.75rem;
  background: var(--input-background);
  border-radius: 8px;
  margin-top: 0.75rem;
  border-left: 3px solid var(--primary-color);
}

.close-popup {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  color: var(--text-secondary);
  font-size: 1.5rem;
  padding: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
}

.close-popup:hover {
  background: var(--input-background);
  color: var(--error-color);
  transform: scale(1.1);
}

/* Mobile styles */
@media screen and (max-width: 1200px) {
  .word-definition-popup {
    right: 1rem;
    width: 340px;
  }
}

@media screen and (max-width: 768px) {
  .word-definition-popup {
    position: fixed;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
    max-height: 80vh;
    width: 90vw;
  }

  .word-header h2 {
    font-size: 1.75rem;
  }

  .popup-content {
    padding: 1.25rem;
  }

  .definition-item {
    padding-left: 1.75rem;
  }

 
}

/* Update word header styles */
.word-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.word-info h2 {
  margin: 0;
  color: var(--primary-color);
  font-size: 1.8rem;
}

.part-of-speech {
  padding: 0.4rem 0.8rem;
  background: var(--input-background);
  border-radius: 20px;
  font-size: 0.9rem;
  color: var(--text-secondary);
  font-style: italic;
  border: 1px solid var(--border-color);
}

/* Definitions section */
.definitions-section {
  margin: 1.5rem 0;
}

.definitions-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.definition-item {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  background: var(--input-background);
  border-radius: 8px;
  border: 1px solid var(--border-color);
}

.definition-number {
  color: var(--text-secondary);
  font-weight: 500;
  opacity: 0.7;
}

/* Example sentences section */
.sentences-section {
  margin: 1.5rem 0;
}

.sentences-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.sentence-item {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  background: var(--input-background);
  border-radius: 8px;
  border: 1px solid var(--border-color);
  color: var(--text-secondary);
  line-height: 1.6;
}

.sentence-number {
  color: var(--text-secondary);
  font-weight: 500;
  opacity: 0.7;
}

/* Mnemonics section */
.mnemonics-section {
  margin: 1.5rem 0;
}

.mnemonics-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.mnemonic-item {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  padding: 1rem;
  background: var(--input-background);
  border-radius: 8px;
  border: 1px solid var(--border-color);
  border-left: 3px solid var(--primary-color);
}

.mnemonic-icon {
  font-size: 1.2rem;
}

.mnemonic-item p {
  margin: 0;
  color: var(--text-secondary);
  line-height: 1.6;
  font-style: italic;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .word-info {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .word-info h2 {
    font-size: 1.5rem;
  }

  .definition-item,
  .sentence-item,
  .mnemonic-item {
    padding: 0.875rem;
    font-size: 0.95rem;
  }
}

/* Media section styles */
.media-section {
  margin: 1.5rem 0;
}

.media-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.media-item {
  border-radius: 8px;
  overflow: hidden;
  background: var(--input-background);
  border: 1px solid var(--border-color);
  aspect-ratio: 16/9;
}

.media-item video,
.media-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

/* Mobile responsiveness for media section */
@media (max-width: 768px) {
  .media-grid {
    grid-template-columns: 1fr;
  }
  
  .media-item {
    max-height: 200px;
  }
}

/* Update card styles for compact view */
.vocabulary-card {
  background: var(--card-background);
  border-radius: 12px;
  padding: 1.25rem;
  border: 1px solid var(--border-color);
  transition: all 0.2s ease;
}

.word-header {
  cursor: pointer;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
}

.word-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  flex-wrap: wrap;
}

.word-info h2 {
  margin: 0;
  color: var(--primary-color);
  font-size: 1.5rem;
}

.primary-definition {
  color: var(--text-secondary);
  margin: 0.5rem 0 0 0;
  font-size: 0.95rem;
  line-height: 1.5;
}

.card-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.expand-button {
  background: var(--input-background);
  border: 1px solid var(--border-color);
  color: var(--text-secondary);
  cursor: pointer;
  padding: 0.5rem;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.expand-button:hover {
  color: var(--primary-color);
  border-color: var(--primary-color);
  background: rgba(0, 210, 255, 0.1);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 210, 255, 0.15);
}

.expand-button:active {
  transform: translateY(0);
  box-shadow: none;
}

.expand-button svg {
  width: 20px;
  height: 20px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .expand-button {
    width: 32px;
    height: 32px;
    padding: 0.4rem;
  }

  .expand-button svg {
    width: 18px;
    height: 18px;
  }
}

/* Compact synonyms container */
.synonyms-container.compact {
  margin-top: 0.5rem;
  padding: 0;
  background: none;
  border: none;
}

.expanded-content {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--border-color);
  animation: expandContent 0.3s ease;
}

@keyframes expandContent {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .vocabulary-card {
    padding: 1rem;
  }

  .word-header {
    flex-direction: column;
    gap: 0.75rem;
  }

  .word-info h2 {
    font-size: 1.3rem;
  }

  .card-actions {
    width: 100%;
    justify-content: space-between;
  }

  .primary-definition {
    font-size: 0.9rem;
  }
}

.synonym-tag.flagged {
  background: rgba(255, 215, 0, 0.15);
  border-color: #ffd700;
  color: #ffd700;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0.4);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(255, 215, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0);
  }
}

/* When a synonym is both mastered and flagged, flagged takes precedence */
.synonym-tag.mastered.flagged {
  background: rgba(255, 215, 0, 0.15);
  border-color: #ffd700;
  color: #ffd700;
}

.vocabulary-card.has-flagged {
  border-color: #ffd700;
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.2);
}

.vocabulary-card.has-flagged:hover {
  box-shadow: 0 0 15px rgba(255, 215, 0, 0.3);
}

.vocabulary-card.has-flagged::before {
  content: '⚠️';
  position: absolute;
  top: -8px;
  right: -8px;
  background: #ffd700;
  color: black;
  padding: 4px;
  border-radius: 50%;
  font-size: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Update existing styles */
.filter-group select {
  min-width: 150px; /* Ensure enough space for new option */
}

/* Enhance visibility of flagged items when using flagged sort */
.vocabulary-card.has-flagged .synonyms-container {
  border-color: rgba(255, 215, 0, 0.3);
}

.synonym-tag.flagged {
  animation: flaggedPulse 2s infinite;
}

@keyframes flaggedPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0.4);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(255, 215, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0);
  }
}

/* Add to existing styles */

.flag-synonym-button {
  background: none;
  border: none;
  padding: 0.25rem;
  margin-left: 0.5rem;
  cursor: pointer;
  color: var(--text-secondary);
  opacity: 0.5;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
}

.flag-synonym-button:hover {
  opacity: 1;
  transform: scale(1.1);
}

.flag-synonym-button .active {
  color: #ffd700;
  opacity: 1;
}

.synonym-tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.synonym-tag.flagged {
  background: rgba(255, 215, 0, 0.15);
  border-color: #ffd700;
  color: #ffd700;
  animation: flagPulse 2s infinite;
}

@keyframes flagPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0.4);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(255, 215, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0);
  }
}

/* When a synonym is both mastered and flagged, flagged takes precedence */
.synonym-tag.mastered.flagged {
  background: rgba(255, 215, 0, 0.15);
  border-color: #ffd700;
  color: #ffd700;
}