.settings-page {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
}

.settings-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  background: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.2s ease;
}

.back-button:hover {
  transform: translateX(-2px);
  border-color: var(--primary-color);
}

.settings-header h1 {
  color: var(--primary-color);
  font-size: 2rem;
  margin: 0;
}

.settings-container {
  background: var(--card-background);
  border-radius: 12px;
  padding: 2rem;
  border: 1px solid var(--border-color);
}

.settings-section {
  margin-bottom: 2rem;
}

.settings-section h2 {
  color: var(--text-color);
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--border-color);
}

.setting-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: var(--input-background);
  border-radius: 8px;
  border: 1px solid var(--border-color);
}

.setting-info {
  flex: 1;
}

.setting-info h3 {
  color: var(--text-color);
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.setting-info p {
  color: var(--text-secondary);
  font-size: 0.95rem;
  line-height: 1.5;
}

.clear-data-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  background: rgba(255, 68, 68, 0.1);
  border: 1px solid var(--error-color);
  border-radius: 8px;
  color: var(--error-color);
  cursor: pointer;
  transition: all 0.2s ease;
}

.clear-data-button:hover {
  background: rgba(255, 68, 68, 0.2);
  transform: translateY(-2px);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 1rem;
}

.modal-content {
  background: var(--card-background);
  border-radius: 12px;
  padding: 2rem;
  width: 100%;
  max-width: 400px;
  position: relative;
  border: 1px solid var(--border-color);
}

.modal-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.warning-icon {
  color: var(--error-color);
  font-size: 1.5rem;
}

.modal-content h3 {
  color: var(--text-color);
  font-size: 1.5rem;
  margin: 0;
}

.modal-content ul {
  color: var(--text-secondary);
  margin: 1rem 0;
  padding-left: 1.5rem;
}

.modal-content li {
  margin-bottom: 0.5rem;
}

.warning-text {
  color: var(--error-color);
  font-weight: 600;
  margin: 1rem 0;
}

.modal-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.modal-buttons button {
  flex: 1;
  padding: 0.875rem;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.cancel-button {
  background: var(--input-background);
  border: 1px solid var(--border-color);
  color: var(--text-color);
}

.cancel-button:hover {
  border-color: var(--primary-color);
  transform: translateY(-2px);
}

.confirm-button {
  background: var(--error-color);
  border: none;
  color: white;
}

.confirm-button:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .settings-page {
    padding: 1rem;
    margin: 1rem;
  }

  .settings-container {
    padding: 1.5rem;
  }

  .setting-item {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }

  .modal-buttons {
    flex-direction: column;
  }
}

.data-list {
  margin: 1rem 0;
  padding-left: 1.5rem;
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.data-list li {
  margin-bottom: 0.5rem;
  list-style-type: circle;
}

.data-list li::marker {
  color: var(--primary-color);
} 