.progress-page {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  color: var(--text-color);
}

.progress-content {
  background-color: var(--card-background);
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 20px var(--shadow-color);
}

.progress-header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  position: relative;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-color);
  text-decoration: none;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  background-color: var(--input-background);
  transition: all 0.2s ease;
}

.back-button:hover {
  background-color: var(--border-color);
  transform: translateX(-2px);
}

.progress-header h1 {
  margin: 0;
  font-size: 1.8rem;
  color: var(--text-color);
}

.practice-container {
  background-color: var(--card-background);
  border-radius: 12px;
  padding: 1.5rem;
  border: 1px solid var(--border-color);
  margin-bottom: 2rem;
}

.practice-section {
  text-align: center;
}

.practice-section h2 {
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}

.start-practice-button {
  background: linear-gradient(135deg, var(--primary-color), #00b3ff);
  color: #000;
  padding: 1rem 2rem;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 200px;
}

.start-practice-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 210, 255, 0.2);
}

.practice-results {
  text-align: center;
  padding: 2rem;
}

.practice-again-button {
  margin-top: 1rem;
  background: linear-gradient(135deg, var(--primary-color), #00b3ff);
  color: #000;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
}

/* Add hover effects for buttons */
.start-practice-button:hover,
.practice-again-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 210, 255, 0.2);
}

/* Responsive Design */
@media (max-width: 768px) {
  .progress-page {
    padding: 1rem;
  }

  .progress-content {
    padding: 1rem;
  }

  .progress-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .header-left {
    width: 100%;
    justify-content: center;
    position: relative;
  }

  .back-button {
    position: absolute;
    left: 0;
  }

  .progress-header h1 {
    flex-grow: 1;
    text-align: center;
    width: 100%;
  }
}

.history-container {
  margin-top: 2rem;
  padding: 1.5rem;
  background-color: var(--card-background);
  border-radius: 12px;
  border: 1px solid var(--border-color);
}

.history-container h2 {
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}

.no-history {
  text-align: center;
  color: var(--text-secondary);
  padding: 2rem;
}

.quiz-history-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.quiz-history-item {
  background-color: var(--card-background);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.quiz-history-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px var(--shadow-color);
}

.quiz-history-item.selected {
  border-color: var(--primary-color);
}

.quiz-history-header {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.quiz-basic-info {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  flex-wrap: wrap;
}

.quiz-date {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.quiz-score {
  color: var(--primary-color);
  font-weight: 600;
}

.quiz-time {
  color: var(--text-secondary);
}

.quiz-type {
  background-color: var(--primary-color);
  color: #000;
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  font-size: 0.85rem;
  font-weight: 600;
}

.quiz-stats {
  display: flex;
  gap: 1.5rem;
}

.stat {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-color);
}

.stat-label {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.quiz-details {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--border-color);
}

.quiz-details h3 {
  color: var(--primary-color);
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.words-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.word-result {
  background-color: var(--input-background);
  padding: 1.2rem;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.word-result h4 {
  color: var(--primary-color);
  margin: 0 0 0.5rem 0;
}

.word-definition {
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.answers-section {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.answer-label {
  color: var(--text-secondary);
  margin-right: 0.5rem;
}

.answer {
  display: inline-block;
  padding: 0.3rem 0.8rem;
  border-radius: 4px;
  margin: 0.2rem;
  font-size: 0.9rem;
}

.answer.correct {
  background-color: rgba(74, 222, 128, 0.1);
  color: var(--secondary-color);
}

.answer.incorrect {
  background-color: rgba(255, 68, 68, 0.1);
  color: var(--error-color);
}

.time-info {
  font-size: 0.9rem;
  color: var(--text-secondary);
  padding: 0.5rem;
  background-color: var(--card-background);
  border-radius: 4px;
}

/* Update responsive styles */
@media (max-width: 768px) {
  .quiz-history-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .quiz-stats {
    flex-wrap: wrap;
    gap: 1rem;
  }

  .stat {
    font-size: 0.9rem;
  }
}

/* Add these styles to your existing ProgressPage.css */

.quiz-summary {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  background-color: var(--input-background);
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.summary-stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.summary-label {
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.summary-value {
  color: var(--primary-color);
  font-size: 1.5rem;
  font-weight: 600;
}

.word-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.word-stats {
  display: flex;
  gap: 1rem;
}

.word-stat {
  padding: 0.3rem 0.8rem;
  border-radius: 12px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.word-stat.correct {
  background-color: rgba(74, 222, 128, 0.1);
  color: var(--secondary-color);
}

.word-stat.incorrect {
  background-color: rgba(255, 68, 68, 0.1);
  color: var(--error-color);
}

.word-stat.time {
  background-color: var(--input-background);
  color: var(--text-secondary);
}

.word-content {
  background-color: var(--input-background);
  padding: 1.5rem;
  border-radius: 8px;
}

.definition-section,
.synonyms-section {
  margin-bottom: 1.5rem;
}

.section-label {
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  display: block;
}

.synonyms-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.synonym-tag {
  padding: 0.4rem 0.8rem;
  border-radius: 12px;
  font-size: 0.9rem;
  background-color: var(--card-background);
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.synonym-tag.correct {
  background-color: rgba(74, 222, 128, 0.1);
  color: var(--secondary-color);
}

.synonym-tag.incorrect {
  background-color: rgba(255, 68, 68, 0.1);
  color: var(--error-color);
}

.tag-icon {
  font-size: 0.8rem;
}

.answers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
}

.answer-column {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.answers-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.answer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 8px;
}

.answer-time {
  font-size: 0.8rem;
  opacity: 0.8;
}

@media (max-width: 768px) {
  .word-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .word-stats {
    width: 100%;
    justify-content: space-between;
  }

  .answers-grid {
    grid-template-columns: 1fr;
  }
}

/* Add to existing ProgressPage.css */

.mastered-words-container {
  background-color: var(--card-background);
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  border: 1px solid var(--border-color);
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.header-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.view-vocabulary-button {
  background: linear-gradient(135deg, var(--primary-color), #00b3ff);
  color: black;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  border: none;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.view-vocabulary-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 210, 255, 0.2);
}

.mastered-words-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.mastered-word-card {
  background-color: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.mastered-word {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 1.1rem;
}

.mastery-date {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.no-mastered {
  color: var(--text-secondary);
  text-align: center;
  padding: 2rem;
  font-style: italic;
}

@media (max-width: 768px) {
  .section-header {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }

  .view-vocabulary-button {
    width: 100%;
    justify-content: center;
  }
}