.flashcards-page {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
}

.flashcards-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  background: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.2s ease;
}

.back-button:hover {
  transform: translateX(-2px);
  border-color: var(--primary-color);
}

.flashcards-header h1 {
  color: var(--primary-color);
  font-size: 2rem;
  margin: 0;
  flex: 1;
}

.view-toggle {
  display: flex;
  gap: 0.5rem;
  background: var(--input-background);
  padding: 0.5rem;
  border-radius: 8px;
  border: 1px solid var(--border-color);
}

.view-toggle button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 6px;
  background: none;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
}

.view-toggle button.active {
  background: var(--primary-color);
  color: black;
}

.flashcard-container {
  perspective: 1000px;
  margin-bottom: 2rem;
  min-height: 400px;
  position: relative;
}

.flashcard {
  width: 100%;
  height: 400px;
  cursor: pointer;
  position: relative;
}

.flashcard-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
}

.flashcard-front,
.flashcard-back {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: var(--card-background);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  box-shadow: 0 4px 20px var(--shadow-color);
  transition: opacity 0.3s ease;
}

.flashcard-front {
  opacity: 1;
  visibility: visible;
}

.flashcard-back {
  opacity: 0;
  visibility: hidden;
}

.flashcard.flipped .flashcard-front {
  opacity: 0;
  visibility: hidden;
}

.flashcard.flipped .flashcard-back {
  opacity: 1;
  visibility: visible;
}

.word-section {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1rem;
}

.word-section h2 {
  color: var(--text-color);
  font-size: 2.5rem;
  margin: 0;
  text-transform: capitalize;
}

.pronunciation-button {
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 0.75rem;
  font-size: 1.75rem;
  transition: all 0.3s ease;
  border-radius: 50%;
}

.pronunciation-button:hover {
  color: var(--primary-color);
  transform: scale(1.1);
  background: rgba(0, 210, 255, 0.1);
}

.pronunciation-button .playing {
  color: var(--primary-color);
  animation: pulse 1s infinite;
}

.part-of-speech {
  color: var(--text-secondary);
  font-style: italic;
  margin: 0.5rem 0 1.5rem;
  font-size: 1.1rem;
}

.definition {
  color: var(--text-color);
  font-size: 1.25rem;
  line-height: 1.6;
  max-width: 80%;
  padding: 1.5rem;
  background: var(--input-background);
  border-radius: 12px;
  border: 1px solid var(--border-color);
}

.synonyms {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  max-width: 90%;
  padding: 1rem;
  overflow-y: auto;
  max-height: 60vh;
}

.synonym-tag {
  padding: 0.75rem 1.5rem;
  background: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: 20px;
  color: var(--text-color);
  font-size: 1.1rem;
  transition: all 0.3s ease;
  white-space: nowrap;
  flex: 0 0 auto;
  width: auto;
}

.synonym-tag.flagged {
  background: rgba(255, 215, 0, 0.15);
  border-color: #ffd700;
  color: #ffd700;
  animation: glow 2s infinite;
}

.flashcard-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  gap: 2rem;
  max-width: 600px;
  padding: 1.5rem;
  background: var(--card-background);
  border-radius: 12px;
  border: 1px solid var(--border-color);
  box-shadow: 0 2px 8px var(--shadow-color);
}

.center-controls {
  display: flex;
  gap: 1.5rem;
  padding: 0 2rem;
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}

.control-button {
  padding: 1rem 1.5rem;
  background: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  font-weight: 500;
  font-size: 1.1rem;
}

.control-button.shuffle,
.control-button.flip {
  padding: 1rem;
  font-size: 1.2rem;
  min-width: 50px;
  background: var(--primary-color);
  color: black;
  border: none;
}

.control-button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 210, 255, 0.2);
}

.control-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.progress-bar {
  height: 6px;
  background: var(--input-background);
  border-radius: 3px;
  margin: 2rem auto 0.5rem;
  overflow: hidden;
  max-width: 600px;
}

.progress {
  height: 100%;
  background: var(--primary-color);
  transition: width 0.5s ease;
}

.card-count {
  text-align: center;
  color: var(--text-secondary);
  font-size: 1rem;
  margin-top: 0.75rem;
  font-weight: 500;
}

.keyboard-shortcuts {
  text-align: center;
  color: var(--text-secondary);
  font-size: 0.95rem;
  margin-top: 2rem;
  padding: 1rem;
  background: var(--card-background);
  border-radius: 8px;
  border: 1px solid var(--border-color);
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

@keyframes pulse {
  0% { opacity: 1; transform: scale(1); }
  50% { opacity: 0.7; transform: scale(0.95); }
  100% { opacity: 1; transform: scale(1); }
}

@keyframes glow {
  0% { box-shadow: 0 0 5px rgba(255, 215, 0, 0.5); }
  50% { box-shadow: 0 0 20px rgba(255, 215, 0, 0.3); }
  100% { box-shadow: 0 0 5px rgba(255, 215, 0, 0.5); }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .flashcards-page {
    padding: 0.5rem;
    margin: 0;
  }

  .flashcards-header {
    flex-direction: column;
    align-items: stretch;
    gap: 0.75rem;
    margin-bottom: 1rem;
    padding: 0.75rem;
  }

  .flashcards-header h1 {
    text-align: center;
    font-size: 1.5rem;
  }

  .back-button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }

  .view-toggle {
    width: 100%;
    padding: 0.25rem;
  }

  .view-toggle button {
    flex: 1;
    padding: 0.5rem;
    font-size: 0.9rem;
  }

  .flashcard-container {
    margin: 0.5rem 0;
    width: 100%;
    max-width: 100%;
    padding: 0 0.5rem;
    box-sizing: border-box;
  }

  .flashcard {
    height: 250px;
    margin: 0;
    width: 100%;
  }

  .flashcard-front,
  .flashcard-back {
    padding: 1rem;
    width: 100%;
    box-sizing: border-box;
  }

  .word-section {
    flex-direction: row;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    justify-content: center;
  }

  .word-section h2 {
    font-size: 1.5rem;
    margin: 0;
  }

  .pronunciation-button {
    font-size: 1.25rem;
    padding: 0.25rem;
  }

  .part-of-speech {
    font-size: 0.9rem;
    margin: 0.25rem 0 0.75rem;
  }

  .definition {
    font-size: 1rem;
    padding: 0.75rem;
    margin: 0;
    max-width: 100%;
    line-height: 1.4;
    box-sizing: border-box;
  }

  .synonyms {
    max-width: 100%;
    padding: 0.5rem;
    gap: 0.5rem;
    box-sizing: border-box;
  }

  .synonym-tag {
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
  }

  .flashcard-controls {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding: 0.75rem;
    margin: 0.75rem 0;
    width: 100%;
    box-sizing: border-box;
  }

  .center-controls {
    order: -1;
    width: 100%;
    justify-content: center;
    padding: 0.5rem 0;
    margin-bottom: 0.25rem;
    border: none;
    gap: 1.5rem;
  }

  .control-button {
    padding: 0.5rem 0.75rem;
    min-width: unset;
    font-size: 0.9rem;
    flex: 1;
  }

  .control-button.shuffle,
  .control-button.flip {
    width: 40px;
    height: 40px;
    padding: 0;
    flex: 0;
  }

  .progress-bar {
    margin: 0.75rem 0 0.25rem;
    height: 4px;
    width: 100%;
  }

  .card-count {
    font-size: 0.85rem;
    margin-top: 0.25rem;
  }

  .keyboard-shortcuts {
    display: none;
  }

  /* Add touch hint */
  .flashcard::after {
    content: 'Tap to flip';
    position: absolute;
    bottom: 0.5rem;
    left: 0;
    right: 0;
    text-align: center;
    color: var(--text-secondary);
    font-size: 0.8rem;
    opacity: 0.7;
    pointer-events: none;
  }

  /* Improve touch targets */
  .control-button,
  .view-toggle button {
    min-height: 44px;
  }

  /* Add swipe hint animation */
  @keyframes swipeHint {
    0% { transform: translateX(0); }
    25% { transform: translateX(10px); }
    75% { transform: translateX(-10px); }
    100% { transform: translateX(0); }
  }

  .flashcard:not(.flipped)::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 1rem;
    right: 1rem;
    height: 2px;
    background: var(--text-secondary);
    opacity: 0.1;
    animation: swipeHint 2s infinite;
    pointer-events: none;
  }
}

/* Small screen optimizations */
@media (max-width: 360px) {
  .flashcards-page {
    padding: 0.25rem;
  }

  .flashcard {
    height: 220px;
  }

  .word-section h2 {
    font-size: 1.25rem;
  }

  .definition {
    font-size: 0.9rem;
  }

  .control-button {
    padding: 0.4rem 0.6rem;
    font-size: 0.85rem;
  }

  .control-button.shuffle,
  .control-button.flip {
    width: 36px;
    height: 36px;
  }
}

/* Add these new styles for incorrect answers section */
.incorrect-answers {
  margin-top: 1.5rem;
  padding: 1rem;
  background: var(--input-background);
  border-radius: 8px;
  border: 1px solid var(--border-color);
}

.incorrect-answers h5 {
  color: var(--error-color);
  margin-bottom: 1rem;
  font-size: 1rem;
}

.incorrect-answer-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem;
  background: rgba(255, 68, 68, 0.1);
  border-radius: 8px;
  margin-bottom: 0.5rem;
}

.incorrect-answer-item:last-child {
  margin-bottom: 0;
}

.incorrect-icon {
  color: var(--error-color);
}

.incorrect-synonym {
  color: var(--error-color);
  font-weight: 500;
}

.arrow {
  color: var(--text-secondary);
}

.user-answer {
  color: var(--text-color);
  background: var(--card-background);
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-size: 0.9rem;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .incorrect-answer-item {
    flex-wrap: wrap;
    padding: 0.5rem;
  }

  .incorrect-answers h5 {
    font-size: 0.95rem;
  }

  .user-answer {
    font-size: 0.85rem;
  }
}