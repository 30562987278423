:root {
  --primary-color: #00d2ff;
  --secondary-color: #4ade80;
  --error-color: #ff4444;
  --background-color: #121212;
  --card-background: #1e1e1e;
  --text-color: #ffffff;
  --text-secondary: #b3b3b3;
  --button-hover: #00b3ff;
  --border-color: #333333;
  --input-background: #2a2a2a;
  --shadow-color: rgba(0, 0, 0, 0.5);
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Inter', 'Segoe UI', system-ui, sans-serif;
  line-height: 1.7;
  letter-spacing: 0.3px;
}

.App {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--card-background);
  box-shadow: 0 4px 20px var(--shadow-color);
  border-radius: 12px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.start-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  padding: 0.75rem;
  max-width: 800px;
  margin: 0 auto;
}

label {
  margin-bottom: 15px;
  font-weight: bold;
}

input[type="range"] {
  width: 100%;
  max-width: 100%;
  margin: 0.5rem 0;
  background-color: var(--input-background);
  cursor: pointer;
}

button {
  background-color: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-color);
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  padding: 14px 28px;
  transition: all 0.2s ease;
}

button:hover {
  transform: translateY(-2px);
  border-color: var(--primary-color);
  box-shadow: 0 4px 12px rgba(0, 210, 255, 0.15);
}

.quiz-container {
  margin-top: 20px;
  width: 100%;
}

.timer, .progress {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--text-secondary);
  margin-bottom: 1rem;
}

.quiz-question {
  background-color: var(--card-background);
  padding: 1.5rem;
  border-radius: 12px;
  margin-top: 1.5rem;
  border: 1px solid var(--border-color);
  box-shadow: 0 2px 12px var(--shadow-color);
}

.quiz-question h2 {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: var(--primary-color);
}

.choices {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns for desktop */
  gap: 16px;
  margin: 20px auto;
  max-width: 800px;
  padding: 0 1rem;
}

.choices button {
  background-color: var(--input-background);
  color: var(--text-color);
  font-size: 1.1rem;
  padding: 1.2rem;
  border: 2px solid var(--border-color);
  border-radius: 12px;
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.4;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
}

.choices button:hover:not(:disabled) {
  background-color: var(--border-color);
  border-color: var(--primary-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 210, 255, 0.1);
}

.choices button.correct {
  background-color: rgba(74, 222, 128, 0.1);
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}

.choices button.incorrect {
  background-color: rgba(255, 68, 68, 0.1);
  border-color: var(--error-color);
  color: var(--error-color);
}

.choices button.selected {
  border-color: var(--primary-color);
}

/* Add ripple effect for button clicks */
.choices button::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, var(--primary-color) 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform .5s, opacity 1s;
}

.choices button:active::after {
  transform: scale(0, 0);
  opacity: .3;
  transition: 0s;
}

/* Maintain single column on mobile */
@media (max-width: 768px) {
  .choices {
    grid-template-columns: 1fr;
    gap: 12px;
    padding: 0 0.5rem;
  }

  .choices button {
    padding: 1rem;
    font-size: 1rem;
    min-height: 50px;
  }
}

.answer {
  margin-top: 1.5rem;
  padding: 1.2rem;
  background-color: var(--input-background);
  border-radius: 8px;
  border-left: 4px solid var(--primary-color);
}

.results-container {
  background-color: var(--card-background);
  padding: 2rem;
  border-radius: 12px;
}

.results-container h2 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.results-container ul {
  list-style-type: none;
  padding: 0;
}

.results-container li {
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.results-container li .correct {
  color: var(--secondary-color);
  font-weight: bold;
}

.results-container li .incorrect {
  color: var(--error-color);
  font-weight: bold;
}

.results-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.result-item {
  background-color: var(--card-background);
  border: 1px solid var(--border-color);
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.result-item h3 {
  color: var(--text-color);
  margin-bottom: 10px;
  border-bottom: 2px solid var(--primary-color);
  padding-bottom: 5px;
}

.answer-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.answer-item .checkmark {
  color: var(--secondary-color);
  margin-right: 5px;
}

.answer-item .cross {
  color: var(--error-color);
  margin-right: 5px;
}

.answer-item .time {
  margin-left: auto;
  font-style: italic;
  color: #9ca3af;
}

.download-btn {
  display: block;
  margin: 20px auto 0;
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-btn:hover {
  background-color: var(--button-hover);
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1.5rem;
  flex-wrap: wrap;
}

@media (max-width: 600px) {
  .choices {
    gap: 10px;
    margin-top: 15px;
  }

  .choices button {
    padding: 0.8rem;
    font-size: 1rem;
  }
}

.result-actions {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.warning {
  color: var(--error-color);
  background-color: rgba(255, 68, 68, 0.1);
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
  font-weight: 500;
}

.download-button {
  background-color: var(--primary-color);
  color: #000000;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 2rem auto;
  padding: 1rem 2rem;
  cursor: pointer; /* Add pointer cursor */
}

.download-icon {
  font-size: 1.2rem;
}

.result-card {
  background-color: var(--card-background);
  border-radius: 12px;
  padding: 1.25rem;
  margin-bottom: 1.5rem;
  border: 1px solid var(--border-color);
  box-shadow: 0 4px 12px var(--shadow-color);
}

.result-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.word-title {
  font-size: 1.8rem;
  margin: 0;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.score-badge {
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-weight: 600;
  font-size: 0.9rem;
}

.word-definition {
  font-size: 1.1rem;
  color: var(--text-secondary);
  margin: 1rem 0;
  padding: 1rem;
  background-color: var(--input-background);
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.synonyms-section {
  margin: 1.5rem 0;
}

.synonyms-title {
  font-size: 1.2rem;
  color: var(--text-color);
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.synonyms-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 0.75rem;
}

.synonym-tag {
  padding: 0.6rem;
  border-radius: 8px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--input-background);
  border: 1px solid var(--border-color);
}

.synonym-tag.correct {
  background-color: rgba(74, 222, 128, 0.1);
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}

.synonym-tag.incorrect {
  background-color: rgba(255, 68, 68, 0.1);
  border-color: var(--error-color);
  color: var(--error-color);
}

.result-icon {
  font-size: 1.2rem;
  margin-left: 0.5rem;
}

.time-info {
  margin-top: 1.5rem;
  color: var(--text-secondary);
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem;
  background-color: var(--input-background);
  border-radius: 8px;
  width: fit-content;
}

.results-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}

.quiz-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.end-quiz-button {
  background-color: var(--error-color);
  color: var(--text-color);
  cursor: pointer; /* Add pointer cursor */
}

.end-quiz-button:hover {
  background-color: #ff2222;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--card-background);
  padding: 2rem;
  border-radius: 12px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 4px 20px var(--shadow-color);
  margin: 1rem;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-content h3 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.modal-content p {
  color: var(--text-secondary);
  margin-bottom: 1.5rem;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.modal-buttons .end-button {
  background-color: var(--error-color);
  color: var(--text-color);
}

.modal-buttons .end-button:hover {
  background-color: #ff2222;
}

/* Add better mobile support */
@media (max-width: 768px) {
  .App {
    margin: 1rem;
    padding: 1rem;
  }

  .quiz-question {
    padding: 1rem;
  }

  .choices {
    grid-template-columns: 1fr;
  }

  .modal-content {
    width: 95%;
    margin: 10px;
    padding: 1rem;
  }
}

/* Add these styles to your existing App.css */

.alphabet-selector {
  width: 100%;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
}

.alphabet-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;
  justify-content: center;
  margin-top: 0.5rem;
  max-width: 800px;
  padding: 0.25rem;
}

.alphabet-button {
  width: 42px;
  height: 42px;
  padding: 0;
  border-radius: 8px;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--input-background);
  color: var(--text-color);
  border: 2px solid var(--border-color);
  transition: all 0.2s ease;
}

.alphabet-button.selected {
  background-color: var(--primary-color);
  color: black;
  border-color: var(--primary-color);
  transform: scale(1.1);
  box-shadow: 0 2px 8px rgba(0, 210, 255, 0.3);
}

.alphabet-button:hover:not(.selected) {
  background-color: var(--border-color);
  transform: translateY(-2px);
}

.selected-letters {
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  background-color: var(--input-background);
  border-radius: 12px;
  text-align: center;
  color: var(--text-secondary);
}

.word-count-selector {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 1.25rem;
  background-color: var(--input-background);
  border-radius: 12px;
  border: 1px solid var(--border-color);
  position: relative;
  box-sizing: border-box;
}

.word-count-selector label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  margin-bottom: 0.75rem;
  color: var(--text-color);
  font-weight: 600;
  width: 100%;
  box-sizing: border-box;
}

.available-words {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  margin-top: 0.5rem;
  color: var(--text-secondary);
  font-size: 1.1rem;
  font-weight: 500;
}

.available-words span {
  color: var(--text-secondary);
}

.synonym-count {
  color: var(--primary-color);
  font-style: italic;
}

.slider-container {
  width: 100%;
  padding: 0 0.75rem;
  position: relative;
  margin-top: 0.5rem;
  box-sizing: border-box;
}

.custom-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: linear-gradient(
    to right,
    rgba(0, 210, 255, 0.4),
    rgba(0, 179, 255, 0.4)
  );
  outline: none;
  margin: 0.5rem 0;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2),
              0 1px 2px rgba(255, 255, 255, 0.05);
  position: relative;
  box-sizing: border-box;
}

.custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: linear-gradient(135deg, var(--primary-color), #00b3ff);
  cursor: pointer;
  border: 3px solid var(--card-background);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3),
              0 0 0 2px rgba(0, 210, 255, 0.2);
  transition: all 0.2s ease;
  margin-top: -9px; /* Center the thumb: (28px - 10px) / 2 = 9px */
}

.custom-slider::-moz-range-thumb {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: linear-gradient(135deg, var(--primary-color), #00b3ff);
  cursor: pointer;
  border: 3px solid var(--card-background);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3),
              0 0 0 2px rgba(0, 210, 255, 0.2);
  transition: all 0.2s ease;
  transform: translateY(-50%); /* Center for Firefox */
}

.custom-slider::-webkit-slider-runnable-track {
  height: 10px;
  border-radius: 5px;
  background: linear-gradient(
    to right,
    rgba(0, 210, 255, 0.4),
    rgba(0, 179, 255, 0.4)
  );
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2),
              0 1px 2px rgba(255, 255, 255, 0.05);
}

.custom-slider::-moz-range-track {
  height: 10px;
  border-radius: 5px;
  background: linear-gradient(
    to right,
    rgba(0, 210, 255, 0.4),
    rgba(0, 179, 255, 0.4)
  );
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2),
              0 1px 2px rgba(255, 255, 255, 0.05);
}

.custom-slider::-webkit-slider-thumb:hover {
  transform: scale(1.15);
  box-shadow: 0 2px 12px rgba(0, 210, 255, 0.4),
              0 0 0 3px rgba(0, 210, 255, 0.3);
}

.custom-slider::-moz-range-thumb:hover {
  transform: scale(1.15);
  box-shadow: 0 2px 12px rgba(0, 210, 255, 0.4),
              0 0 0 3px rgba(0, 210, 255, 0.3);
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-top: 0.5rem;
  color: var(--text-secondary);
  font-size: 0.9rem;
  font-weight: 500;
  width: 100%;
  box-sizing: border-box;
}

.slider-labels span {
  position: relative;
  text-align: center;
  min-width: 24px;
}

.slider-labels span:first-child {
  text-align: left;
}

.slider-labels span:last-child {
  text-align: right;
}

/* Mobile optimizations */
@media (max-width: 480px) {
  .word-count-selector {
    padding: 1rem;
  }

  .slider-container {
    padding: 0 0.5rem;
  }

  input[type="range"] {
    width: calc(100% - 1rem); /* Adjust for smaller padding on mobile */
  }
}

/* Update header styles */
.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding: 0 1rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.app-header h1 {
  margin: 0;
  color: var(--primary-color);
  font-size: 2.5rem;
}

.header-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.header-button {
  padding: 0.6rem 1.2rem;
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: 500;
  border: 1px solid var(--border-color);
  background-color: var(--input-background);
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.2s ease;
}

.header-button:hover {
  background-color: var(--border-color);
  transform: translateY(-1px);
}

.header-button.warning {
  color: var(--error-color);
  border-color: var(--error-color);
  background-color: rgba(255, 68, 68, 0.1);
}

.header-button.warning:hover {
  background-color: rgba(255, 68, 68, 0.2);
}

/* Update start container to remove menu buttons */
.start-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  max-width: 800px;
  margin: 0 auto;
}

.selected-letters {
  margin: 0.25rem 0;
  padding: 0.4rem 0.75rem;
  background-color: var(--input-background);
  border-radius: 12px;
  text-align: center;
  color: var(--text-secondary);
}

.word-count-selector {
  margin: 1rem auto 2rem;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .app-header {
    grid-template-columns: auto 1fr auto;
    padding: 0.75rem;
    gap: 1rem;
  }

  .app-header h1 {
    font-size: 1.8rem;
  }

  .menu-dropdown {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 16px 16px 0 0;
    animation: slideUp 0.3s ease;
  }

  .menu-icons {
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    justify-content: center;
  }

  .menu-icon-button {
    width: 56px;
    height: 56px;
  }

  .start-container {
    gap: 1rem;
    padding: 0.5rem;
  }
}

/* Update menu trigger styles */
.menu-trigger {
  background: linear-gradient(135deg, var(--input-background), var(--card-background));
  border: 2px solid var(--border-color);
  padding: 0.75rem;
  width: 44px;
  height: 44px;
  color: var(--text-color);
  cursor: pointer;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
}

.menu-trigger::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: inherit;
  transition: all 0.3s ease;
}

.menu-trigger:hover {
  transform: translateY(-2px);
  border-color: var(--primary-color);
  box-shadow: 0 4px 12px rgba(0, 210, 255, 0.15);
}

.menu-trigger:hover::before {
  opacity: 0.8;
}

.menu-trigger:active {
  transform: translateY(0);
}

.menu-trigger svg {
  width: 24px;
  height: 24px;
  stroke-width: 2px;
  transition: all 0.3s ease;
}

.menu-trigger:hover svg {
  color: var(--primary-color);
  transform: scale(1.1);
}

/* Add pulse animation when menu is open */
.menu-trigger.active {
  border-color: var(--primary-color);
  background: linear-gradient(135deg, rgba(0, 210, 255, 0.1), rgba(0, 179, 255, 0.1));
}

.menu-trigger.active svg {
  color: var(--primary-color);
}

/* Update menu dropdown styles */
.menu-dropdown {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  background-color: var(--card-background);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  padding: 0.75rem;
  min-width: 200px;
  box-shadow: 0 4px 20px var(--shadow-color);
  z-index: 1000;
}

.menu-icons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.menu-icon-button {
  width: 100%;
  padding: 0.75rem;
  border-radius: 8px;
  background-color: var(--input-background);
  border: 1px solid var(--border-color);
  color: var(--text-color);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.menu-button-content {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
}

.menu-button-label {
  font-size: 0.95rem;
  font-weight: 500;
  white-space: nowrap;
}

.menu-icon-button:hover {
  transform: translateY(-2px);
  border-color: var(--primary-color);
  box-shadow: 0 4px 12px rgba(0, 210, 255, 0.15);
}

.menu-icon-button.warning {
  color: var(--error-color);
}

.menu-icon-button.warning:hover {
  border-color: var(--error-color);
  box-shadow: 0 4px 12px rgba(255, 68, 68, 0.15);
}

/* Mobile styles */
@media (max-width: 768px) {
  .menu-dropdown {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 12px 12px 0 0;
    padding: 1rem;
    min-width: auto;
    animation: slideUp 0.3s ease;
  }

  .menu-icon-button {
    padding: 1rem;
  }

  .menu-button-label {
    font-size: 1rem;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

/* Add ripple effect */
.menu-trigger::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%, -50%);
  transform-origin: 50% 50%;
}

.menu-trigger:active::after {
  animation: ripple 0.6s ease-out;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }
  100% {
    transform: scale(40, 40);
    opacity: 0;
  }
}

/* Additional height-based adjustments */
@media (max-height: 700px) {
  .app-header {
    margin-bottom: 0.75rem;
  }

  .start-container {
    gap: 1rem;
  }

  .alphabet-selector {
    gap: 0.5rem;
  }

  .word-count-selector {
    padding: 0.75rem;
  }

  .selected-letters {
    margin: 0.25rem 0;
    padding: 0.4rem 0.75rem;
  }
}

/* Update base button styles */
button {
  background-color: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-color);
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  padding: 14px 28px;
  transition: all 0.2s ease;
}

button:hover {
  transform: translateY(-2px);
  border-color: var(--primary-color);
  box-shadow: 0 4px 12px rgba(0, 210, 255, 0.15);
}

/* Start button */
.start-button {
  background-color: var(--input-background);
  color: var(--text-color);
  padding: 1rem 2.5rem;
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 600;
  min-width: 200px;
  margin-top: 1rem;
}

.start-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
  border-color: var(--border-color);
}

/* Submit and Next buttons */
.submit-answer-button,
.next-word-button,
.practice-again-button {
  background-color: var(--input-background);
  color: var(--text-color);
  padding: 0.875rem 1.5rem;
  border-radius: 8px;
  font-weight: 600;
}

/* Warning/Reset button */
.reset-button {
  background-color: rgba(255, 68, 68, 0.05);
  color: var(--error-color);
  border-color: var(--error-color);
}

.reset-button:hover {
  background-color: rgba(255, 68, 68, 0.1);
  border-color: var(--error-color);
  box-shadow: 0 4px 12px rgba(255, 68, 68, 0.15);
}

/* Hint button */
.hint-button {
  background-color: var(--input-background);
  color: var(--text-color);
  border: 1px solid var(--border-color);
}

.hint-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

/* Menu buttons */
.menu-icon-button {
  background-color: var(--input-background);
  border: 1px solid var(--border-color);
}

.menu-icon-button:hover {
  transform: translateY(-2px);
  border-color: var(--primary-color);
  box-shadow: 0 4px 12px rgba(0, 210, 255, 0.15);
}

.menu-icon-button.warning:hover {
  border-color: var(--error-color);
  box-shadow: 0 4px 12px rgba(255, 68, 68, 0.15);
}

/* Mobile styles */
@media (max-width: 768px) {
  button {
    padding: 12px 24px;
  }

  .start-button {
    padding: 0.875rem 2rem;
    font-size: 1rem;
  }

  .submit-answer-button,
  .next-word-button,
  .practice-again-button {
    padding: 0.75rem 1.25rem;
  }
}

/* Active state for buttons */
button:active {
  transform: translateY(0);
  box-shadow: none;
}

/* Disabled state */
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
  border-color: var(--border-color);
}

/* Update menu container styles */
.menu-container {
  position: relative;
  z-index: 1000;
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.menu-dropdown {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  background-color: var(--card-background);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  padding: 0.75rem;
  min-width: 200px;
  box-shadow: 0 4px 20px var(--shadow-color);
  z-index: 1000;
}

.menu-icons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.menu-icon-button {
  width: 100%;
  padding: 0.75rem;
  border-radius: 8px;
  background-color: var(--input-background);
  border: 1px solid var(--border-color);
  color: var(--text-color);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.menu-button-content {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
}

.menu-button-label {
  font-size: 0.95rem;
  font-weight: 500;
  white-space: nowrap;
}

.menu-icon-button:hover {
  transform: translateY(-2px);
  border-color: var(--primary-color);
  box-shadow: 0 4px 12px rgba(0, 210, 255, 0.15);
}

.menu-icon-button.warning {
  color: var(--error-color);
}

.menu-icon-button.warning:hover {
  border-color: var(--error-color);
  box-shadow: 0 4px 12px rgba(255, 68, 68, 0.15);
}

/* Mobile styles */
@media (max-width: 768px) {
  .menu-dropdown {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 12px 12px 0 0;
    padding: 1rem;
    min-width: auto;
    animation: slideUp 0.3s ease;
  }

  .menu-icon-button {
    padding: 1rem;
  }

  .menu-button-label {
    font-size: 1rem;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

/* Add ripple effect */
.menu-trigger::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%, -50%);
  transform-origin: 50% 50%;
}

.menu-trigger:active::after {
  animation: ripple 0.6s ease-out;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }
  100% {
    transform: scale(40, 40);
    opacity: 0;
  }
}

/* Additional height-based adjustments */
@media (max-height: 700px) {
  .app-header {
    margin-bottom: 0.75rem;
  }

  .start-container {
    gap: 1rem;
  }

  .alphabet-selector {
    gap: 0.5rem;
  }

  .word-count-selector {
    padding: 0.75rem;
  }

  .selected-letters {
    margin: 0.25rem 0;
    padding: 0.4rem 0.75rem;
  }
}

/* Update slider container and input styles */
.word-count-selector {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 1.25rem;
  background-color: var(--input-background);
  border-radius: 12px;
  border: 1px solid var(--border-color);
  position: relative;
  box-sizing: border-box;
}

.word-count-selector label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  margin-bottom: 0.75rem;
  color: var(--text-color);
  font-weight: 600;
  width: 100%;
  box-sizing: border-box;
}

.slider-container {
  width: 100%;
  padding: 0 0.75rem;
  position: relative;
  margin-top: 0.5rem;
  box-sizing: border-box;
}

input[type="range"] {
  -webkit-appearance: none;
  width: calc(100% - 1.5rem); /* Account for container padding */
  margin: 0.5rem auto;
  background-color: var(--input-background);
  cursor: pointer;
  display: block;
  box-sizing: border-box;
}

.custom-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: linear-gradient(
    to right,
    rgba(0, 210, 255, 0.4),
    rgba(0, 179, 255, 0.4)
  );
  outline: none;
  margin: 0.5rem 0;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2),
              0 1px 2px rgba(255, 255, 255, 0.05);
  position: relative;
  box-sizing: border-box;
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-top: 0.5rem;
  color: var(--text-secondary);
  font-size: 0.9rem;
  font-weight: 500;
  width: 100%;
  box-sizing: border-box;
}

.slider-labels span {
  position: relative;
  text-align: center;
  min-width: 24px;
}

.slider-labels span:first-child {
  text-align: left;
}

.slider-labels span:last-child {
  text-align: right;
}

/* Mobile optimizations */
@media (max-width: 480px) {
  .word-count-selector {
    padding: 1rem;
  }

  .slider-container {
    padding: 0 0.5rem;
  }

  input[type="range"] {
    width: calc(100% - 1rem); /* Adjust for smaller padding on mobile */
  }
}

/* Update the available words styling */
.available-words {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  margin-top: 0.5rem;
  color: var(--text-secondary);
  font-size: 1.1rem;
  font-weight: 500;
}

.available-words span {
  color: var(--text-secondary);
}

.synonym-count {
  color: var(--primary-color);
  font-style: italic;
}

/* Remove these classes as they're no longer needed */
.total-available {
  display: none;
}

