.quiz-card {
  background-color: var(--card-background);
  border-radius: 12px;
  padding: 2rem;
  border: 1px solid var(--border-color);
}

.quiz-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.quiz-header h2 {
  color: var(--text-color);
  font-size: 1.2rem;
}

.score-tracker {
  color: var(--primary-color);
  font-weight: 600;
}

.question {
  font-size: 1.3rem;
  color: var(--text-color);
  margin-bottom: 2rem;
  text-align: center;
}

.options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
}

.option-button {
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  background-color: var(--input-background);
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.2s ease;
}

.option-button:hover:not(:disabled) {
  background-color: var(--border-color);
}

.option-button.selected {
  border-color: var(--primary-color);
}

.option-button.correct {
  background-color: rgba(74, 222, 128, 0.1);
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}

.option-button.incorrect {
  background-color: rgba(255, 68, 68, 0.1);
  border-color: var(--error-color);
  color: var(--error-color);
}

.quiz-button {
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  background-color: var(--primary-color);
  color: black;
  border: none;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.quiz-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.quiz-button:hover:not(:disabled) {
  transform: translateY(-2px);
}

.feedback {
  text-align: center;
  margin: 1rem 0;
  padding: 0.75rem;
  border-radius: 8px;
  font-weight: 600;
}

.feedback.correct {
  background-color: rgba(74, 222, 128, 0.1);
  color: var(--secondary-color);
}

.feedback.incorrect {
  background-color: rgba(255, 68, 68, 0.1);
  color: var(--error-color);
}

.result {
  text-align: center;
}

.score-display {
  margin: 2rem 0;
}

.score {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.percentage {
  font-size: 1.5rem;
  color: var(--text-secondary);
}

.no-questions {
  text-align: center;
  color: var(--text-secondary);
}

@media (max-width: 480px) {
  .options {
    grid-template-columns: 1fr;
  }

  .quiz-card {
    padding: 1rem;
  }

  .question {
    font-size: 1.1rem;
  }
} 