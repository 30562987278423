.math-app {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--card-background);
  box-shadow: 0 4px 20px var(--shadow-color);
  border-radius: 12px;
}

.math-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  gap: 1rem;
}

.math-title-icon {
  font-size: 2.5rem;
  margin-right: 0.5rem;
}

.math-app h1 {
  font-size: 2.5rem;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  margin: 0;
}

.tabs {
  margin-bottom: 2rem;
}

.tabs-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  background-color: var(--input-background);
  padding: 1rem;
  border-radius: 12px;
  border: 1px solid var(--border-color);
}

.tab {
  padding: 1rem 1.5rem;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  background: none;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 1.1rem;
}

.tab-icon {
  font-size: 1.2rem;
}

.tab:hover {
  background-color: var(--border-color);
  transform: translateY(-2px);
}

.tab.active {
  background-color: var(--primary-color);
  color: black;
  border-color: var(--primary-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 210, 255, 0.2);
}

.categories {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.category-button {
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  background-color: var(--input-background);
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 1.1rem;
}

.category-icon {
  font-size: 1.2rem;
}

.category-button:hover {
  background-color: var(--border-color);
  transform: translateY(-2px);
}

.category-button.active {
  background-color: var(--primary-color);
  color: black;
  border-color: var(--primary-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 210, 255, 0.2);
}

.progress-card {
  background-color: var(--input-background);
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  border: 1px solid var(--border-color);
}

.card-header {
  margin-bottom: 1.5rem;
}

.card-header h2 {
  font-size: 1.5rem;
  color: var(--text-color);
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.header-icon {
  font-size: 1.4rem;
}

.card-description {
  color: var(--text-secondary);
  font-size: 1rem;
}

.progress-item {
  margin-bottom: 1rem;
  padding: 0.75rem;
  background-color: var(--card-background);
  border-radius: 8px;
  border: 1px solid var(--border-color);
}

.progress-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
  color: var(--text-color);
}

.progress-value {
  font-weight: 600;
  color: var(--primary-color);
}

.progress-bar-container {
  width: 100%;
  height: 8px;
  background-color: var(--input-background);
  border-radius: 4px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: var(--primary-color);
  transition: all 0.3s ease;
}

.tab-content {
  background-color: var(--input-background);
  border-radius: 12px;
  padding: 2rem;
  border: 1px solid var(--border-color);
}

.progress-button {
  padding: 0.75rem 1.25rem;
  border-radius: 8px;
  background-color: var(--input-background);
  border: 1px solid var(--border-color);
  color: var(--text-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
}

.progress-button:hover {
  background-color: var(--border-color);
  transform: translateY(-2px);
}

.progress-icon {
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .math-app {
    margin: 1rem;
    padding: 1rem;
  }

  .math-header {
    flex-direction: row;
    flex-wrap: wrap;
    text-align: left;
  }

  .math-app h1 {
    order: -1;
    width: 100%;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .tabs-list {
    grid-template-columns: 1fr;
    padding: 0.75rem;
  }

  .tab {
    padding: 0.75rem;
  }

  .categories {
    grid-template-columns: repeat(2, 1fr);
  }

  .category-button {
    padding: 0.75rem;
    font-size: 1rem;
  }

  .progress-item {
    padding: 0.5rem;
  }
} 