.practice-page {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  color: var(--text-color);
}

.practice-content {
  background-color: var(--card-background);
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 20px var(--shadow-color);
}

.practice-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.practice-header h1 {
  margin: 0;
  font-size: 2rem;
  color: var(--primary-color);
}

.practice-question {
  background-color: var(--input-background);
  padding: 2rem;
  border-radius: 12px;
  margin-top: 1rem;
}

.word-progress {
  text-align: center;
  color: var(--text-secondary);
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
}

.practice-word {
  text-align: center;
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.word-definition {
  text-align: center;
  color: var(--text-secondary);
  margin-bottom: 2rem;
  font-size: 1.1rem;
  line-height: 1.6;
}

.answer-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 400px;
  margin: 0 auto;
}

.answer-section input {
  padding: 1rem;
  border-radius: 8px;
  border: 2px solid var(--border-color);
  background-color: var(--card-background);
  color: var(--text-color);
  font-size: 1rem;
  transition: all 0.3s ease;
  width: 100%;
}

.answer-section input:focus {
  outline: none;
  border-color: var(--primary-color);
}

.submit-answer-button,
.next-word-button {
  padding: 1rem;
  border-radius: 8px;
  border: none;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-answer-button {
  background: linear-gradient(135deg, var(--primary-color), #00b3ff);
  color: #000;
}

.next-word-button {
  background: var(--secondary-color);
  color: #000;
}

.submit-answer-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.answer-feedback {
  margin-top: 2rem;
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;
}

.answer-feedback.correct {
  background-color: rgba(74, 222, 128, 0.1);
  color: var(--secondary-color);
}

.answer-feedback.incorrect {
  background-color: rgba(255, 68, 68, 0.1);
  color: var(--error-color);
}

.answer-feedback h3 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.practice-results {
  padding: 2rem;
}

.results-summary {
  text-align: center;
  margin-bottom: 3rem;
}

.score-display {
  font-size: 3rem;
  color: var(--primary-color);
  margin: 1rem 0;
  font-weight: bold;
}

.results-details {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 2rem 0;
}

.result-item {
  background-color: var(--input-background);
  padding: 1.5rem;
  border-radius: 12px;
  border: 1px solid var(--border-color);
}

.result-item.correct {
  border-left: 4px solid var(--secondary-color);
}

.result-item.incorrect {
  border-left: 4px solid var(--error-color);
}

.result-item h3 {
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.definition {
  color: var(--text-secondary);
  margin-bottom: 1rem;
}

.answer-details {
  background-color: var(--card-background);
  padding: 1rem;
  border-radius: 8px;
}

.user-answer {
  color: var(--primary-color);
  font-weight: 600;
}

.practice-again-button {
  display: block;
  margin: 2rem auto 0;
  padding: 1rem 2rem;
  background: linear-gradient(135deg, var(--primary-color), #00b3ff);
  color: #000;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.practice-again-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 210, 255, 0.2);
}

@media (max-width: 768px) {
  .practice-page {
    padding: 1rem;
  }

  .practice-content {
    padding: 1rem;
  }

  .practice-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .practice-word {
    font-size: 2rem;
  }
}

.practice-stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  background-color: var(--card-background);
  padding: 1rem;
  border-radius: 8px;
}

.streak-counter {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 1.1rem;
}

.timer {
  color: var(--text-secondary);
  font-weight: 500;
}

.button-group {
  display: flex;
  gap: 1rem;
  justify-content: center;
  width: 100%;
}

.hint-button {
  background-color: var(--input-background);
  color: var(--text-color);
  border: 1px solid var(--border-color);
}

.hint {
  text-align: center;
  color: var(--text-secondary);
  background-color: var(--input-background);
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.hint-label {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  color: var(--text-secondary);
}

.hint-words {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  justify-content: center;
}

.hint-word {
  font-family: monospace;
  letter-spacing: 2px;
  background-color: var(--card-background);
  padding: 0.5rem 1rem;
  border-radius: 6px;
  border: 1px solid var(--border-color);
}

.score-display {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}

.score-percentage {
  font-size: 3rem;
  color: var(--primary-color);
  font-weight: bold;
}

.score-points {
  font-size: 1.5rem;
  color: var(--text-secondary);
}

.result-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.result-stats {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.hint-used {
  background-color: var(--input-background);
  color: var(--text-secondary);
  padding: 0.3rem 0.8rem;
  border-radius: 12px;
  font-size: 0.8rem;
}

.time-taken {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.practice-actions {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
}

.return-button {
  padding: 1rem 2rem;
  background-color: var(--input-background);
  color: var(--text-color);
  text-decoration: none;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.return-button:hover {
  background-color: var(--border-color);
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .practice-stats {
    flex-direction: column;
    gap: 0.8rem;
    text-align: center;
  }

  .button-group {
    flex-direction: column;
    align-items: center;
  }

  .result-header {
    flex-direction: column;
    gap: 0.8rem;
  }

  .practice-actions {
    flex-direction: column;
  }

  .submit-answer-button,
  .next-word-button,
  .hint-button {
    width: 100%;
    max-width: 300px;
  }
}

@media (max-width: 480px) {
  .hint {
    padding: 0.75rem;
  }
  
  .hint-words {
    gap: 0.5rem;
  }
  
  .hint-word {
    padding: 0.4rem 0.75rem;
    font-size: 0.9rem;
  }
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.input-container input {
  width: 100%;
}

.answer-tooltip {
  padding: 0.5rem;
}

.answer-tooltip p {
  margin: 0.25rem 0;
  white-space: nowrap;
  font-size: 0.9rem;
}

.answer-tooltip p:last-child {
  color: var(--text-secondary);
  font-style: italic;
  font-size: 0.8rem;
}

@media (max-width: 480px) {
  .input-container {
    flex-direction: row;
    align-items: center;
  }

  .answer-tooltip {
    font-size: 0.8rem;
  }
  
  .answer-tooltip p {
    margin: 0.2rem 0;
  }
}

.answer-details {
  margin-top: 1rem;
  padding: 1rem;
  background-color: var(--card-background);
  border-radius: 8px;
}

.user-answers {
  margin-bottom: 1rem;
}

.user-answers p {
  color: var(--text-secondary);
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.answer-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.answer-chip {
  padding: 0.4rem 0.8rem;
  border-radius: 16px;
  font-size: 0.9rem;
  display: inline-flex;
  align-items: center;
}

.answer-chip.correct {
  background-color: rgba(74, 222, 128, 0.1);
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
}

.answer-chip.incorrect {
  background-color: rgba(255, 68, 68, 0.1);
  color: var(--error-color);
  border: 1px solid var(--error-color);
}

.correct-synonyms {
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.correct-synonyms span {
  font-weight: 600;
}

@media (max-width: 480px) {
  .answer-chips {
    gap: 0.4rem;
  }

  .answer-chip {
    padding: 0.3rem 0.6rem;
    font-size: 0.85rem;
  }
} 