.quiz-page {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
}

.quiz-header {
  background: var(--card-background);
  padding: 1.5rem;
  border-radius: 12px;
  margin-bottom: 2rem;
  border: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quiz-progress {
  color: var(--text-secondary);
  font-size: 1.1rem;
  font-weight: 500;
}

.question-container {
  background: var(--card-background);
  border-radius: 12px;
  padding: 2rem;
  border: 1px solid var(--border-color);
  box-shadow: 0 4px 20px var(--shadow-color);
}

.word {
  color: var(--primary-color);
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.definition {
  color: var(--text-secondary);
  text-align: center;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  padding: 1rem;
  background: var(--input-background);
  border-radius: 8px;
  border: 1px solid var(--border-color);
}

.choices {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.choice-button {
  background: var(--input-background);
  border: 1px solid var(--border-color);
  padding: 1.25rem;
  border-radius: 12px;
  font-size: 1.1rem;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.2s ease;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.3;
}

.choice-button:hover {
  transform: translateY(-2px);
  border-color: var(--primary-color);
  background: rgba(0, 210, 255, 0.1);
}

.end-quiz-button {
  background: rgba(255, 68, 68, 0.1);
  color: var(--error-color);
  border: 1px solid var(--error-color);
  padding: 0.75rem 1.25rem;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.end-quiz-button:hover {
  background: rgba(255, 68, 68, 0.2);
  transform: translateY(-2px);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 1rem;
}

.modal-content {
  background: var(--card-background);
  border-radius: 12px;
  padding: 2rem;
  width: 100%;
  max-width: 400px;
  position: relative;
  border: 1px solid var(--border-color);
}

.close-modal {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.close-modal:hover {
  color: var(--error-color);
  transform: scale(1.1);
}

.modal-content h3 {
  color: var(--text-color);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.modal-content p {
  color: var(--text-secondary);
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.modal-buttons {
  display: flex;
  gap: 1rem;
}

.modal-buttons button {
  flex: 1;
  padding: 0.875rem;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.cancel-button {
  background: var(--input-background);
  border: 1px solid var(--border-color);
  color: var(--text-color);
}

.cancel-button:hover {
  border-color: var(--primary-color);
  transform: translateY(-2px);
}

.confirm-button {
  background: var(--error-color);
  border: none;
  color: white;
}

.confirm-button:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

.quiz-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
  position: relative;
}

.flag-button {
  background: var(--input-background);
  border: 1px solid var(--border-color);
  color: var(--text-secondary);
  padding: 0.75rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.flag-button:hover .flag-tooltip {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

.flag-tooltip {
  position: absolute;
  bottom: calc(100% + 10px);
  right: 0;
  background: var(--card-background);
  border: 1px solid var(--border-color);
  padding: 0.75rem 1rem;
  border-radius: 8px;
  font-size: 0.9rem;
  color: var(--text-color);
  width: 200px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(5px);
  transition: all 0.2s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  z-index: 100;
}

.flag-tooltip::after {
  content: '';
  position: absolute;
  bottom: -6px;
  right: 20px;
  width: 10px;
  height: 10px;
  background: var(--card-background);
  border-right: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  transform: rotate(45deg);
}

.flag-button.flagged {
  color: #ffd700;
  border-color: #ffd700;
  background: rgba(255, 215, 0, 0.1);
  animation: flagPulse 2s infinite;
}

@keyframes flagPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0.4);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(255, 215, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0);
  }
}

.choice-button.correct {
  background: rgba(74, 222, 128, 0.1);
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}

.choice-button.incorrect {
  background: rgba(255, 68, 68, 0.1);
  border-color: var(--error-color);
  color: var(--error-color);
}

.show-answer-button {
  margin-top: 1.5rem;
  width: 100%;
  padding: 1rem;
  background: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-color);
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.show-answer-button:hover {
  background: rgba(0, 210, 255, 0.1);
  border-color: var(--primary-color);
}

.answer-section {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid var(--border-color);
  animation: fadeIn 0.3s ease;
}

.answer-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.part-of-speech {
  color: var(--text-secondary);
  font-style: italic;
  font-size: 0.95rem;
}

.definition {
  padding: 1rem;
  background: var(--input-background);
  border-radius: 8px;
  border: 1px solid var(--border-color);
  color: var(--text-color);
  line-height: 1.6;
}

.synonym-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.answer-synonym {
  padding: 0.5rem 1rem;
  background: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: 20px;
  font-size: 0.9rem;
}

.answer-synonym.correct {
  background: rgba(74, 222, 128, 0.1);
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}

.example-sentence,
.mnemonic {
  padding: 1rem;
  background: var(--input-background);
  border-radius: 8px;
  border: 1px solid var(--border-color);
}

.example-sentence h4,
.mnemonic h4 {
  color: var(--text-color);
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
}

.example-sentence p,
.mnemonic p {
  color: var(--text-secondary);
  line-height: 1.6;
  font-style: italic;
}

.media-section {
  margin-top: 1rem;
}

.media-section img,
.media-section video {
  max-width: 100%;
  border-radius: 8px;
  margin-top: 0.5rem;
}

.continue-button {
  margin-top: 1rem;
  padding: 1rem;
  background: var(--primary-color);
  border: none;
  border-radius: 8px;
  color: black;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.continue-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 210, 255, 0.3);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.timer-bar-container {
  position: relative;
  width: 100%;
  height: 6px;
  background: var(--input-background);
  border-radius: 3px;
  margin-bottom: 2rem;
  overflow: hidden;
}

.timer-bar {
  height: 100%;
  background: var(--secondary-color);
  transition: width 1s linear;
}

.timer-bar.warning {
  animation: pulse 1s infinite;
}

.timer-text {
  position: absolute;
  right: 0;
  top: -25px;
  color: var(--text-secondary);
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .quiz-page {
    padding: 1rem;
    margin: 1rem;
  }

  .quiz-header {
    padding: 1rem;
  }

  .question-container {
    padding: 1.5rem;
  }

  .word {
    font-size: 2rem;
  }

  .choices {
    grid-template-columns: 1fr;
    gap: 0.75rem;
  }

  .choice-button {
    padding: 1rem;
    font-size: 1rem;
    min-height: 60px;
  }

  .modal-content {
    margin: 1rem;
    padding: 1.5rem;
  }

  .modal-buttons {
    flex-direction: column;
  }

  .end-quiz-button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }

  .answer-section {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
  }

  .answer-content {
    gap: 1rem;
  }

  .continue-button {
    padding: 0.875rem;
    font-size: 0.95rem;
  }

  .flag-tooltip {
    width: 180px;
    font-size: 0.85rem;
    padding: 0.625rem 0.875rem;
  }

  .timer-text {
    font-size: 0.85rem;
  }
} 