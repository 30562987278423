.tooltip-container {
  position: relative;
  display: inline-block;
  margin-left: 8px;
}

.tooltip-icon {
  cursor: help;
  color: var(--text-secondary);
  font-size: 1rem;
}

.tooltip-content {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 12px;
  background-color: var(--card-background);
  border: 1px solid var(--border-color);
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  color: var(--text-color);
  font-size: 0.9rem;
  white-space: nowrap;
  z-index: 1000;
  margin-bottom: 8px;
}

.tooltip-content::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: var(--border-color) transparent transparent transparent;
} 