.reference-card {
  background-color: var(--card-background);
  border-radius: 12px;
  padding: 2rem;
  border: 1px solid var(--border-color);
}

.reference-header {
  text-align: center;
  margin-bottom: 2rem;
}

.reference-header h2 {
  color: var(--text-color);
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

.reference-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
  max-height: 600px;
  overflow-y: auto;
  padding: 0.5rem;
}

.conversion-card {
  background-color: var(--input-background);
  border-radius: 12px;
  padding: 1.5rem;
  border: 1px solid var(--border-color);
  transition: all 0.2s ease;
}

.conversion-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.conversion-icon {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
  position: relative;
  cursor: help;
}

.tip-tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--card-background);
  padding: 0.75rem;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  font-size: 0.9rem;
  color: var(--text-color);
  width: max-content;
  max-width: 200px;
  z-index: 10;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.conversion-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.unit-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.unit-label {
  color: var(--text-color);
  font-weight: 500;
  font-size: 1.1rem;
}

.unit-input {
  width: 80px;
  padding: 0.5rem;
  border-radius: 6px;
  border: 1px solid var(--border-color);
  background-color: var(--card-background);
  color: var(--text-color);
  text-align: center;
}

.unit-result {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 1.1rem;
}

.arrow {
  text-align: center;
  color: var(--text-secondary);
  font-size: 1.5rem;
}

.conversion-formula {
  text-align: center;
  color: var(--text-secondary);
  font-size: 0.9rem;
  padding-top: 0.5rem;
  border-top: 1px solid var(--border-color);
}

.visual-conversion {
  background-color: var(--card-background);
  padding: 2rem;
  border-radius: 8px;
  margin-top: 1rem;
  text-align: center;
}

.visual-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  margin: 1.5rem 0;
}

.view-title {
  font-size: 1.8rem;
  color: var(--text-color);
  margin-bottom: 1rem;
}

.square-grid {
  position: relative;
  border: 2px solid black;
  border-radius: 4px;
  overflow: hidden;
}

.square-grid.cup {
  width: 150px;
  height: 150px;
}

.square-grid.pint {
  width: 300px;
  height: 150px;
}

.square-grid.quart,
.square-grid.gallon {
  width: 300px;
  height: 300px;
}

.square-row {
  display: flex;
  height: 25%;
}

.square-cell {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
  color: black;
  border: 1px solid black;
}

.dotted-lines {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.vertical-line,
.horizontal-line {
  position: absolute;
  border: 1px dashed black;
}

.vertical-line {
  top: 0;
  left: 50%;
  height: 100%;
}

.horizontal-line {
  left: 0;
  top: 50%;
  width: 100%;
}

.visual-description {
  color: var(--text-secondary);
  font-size: 1.1rem;
  margin-top: 1rem;
}

.conversion-labels {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.label-item {
  color: var(--text-secondary);
  font-size: 0.9rem;
  padding: 0.5rem;
  background-color: var(--input-background);
  border-radius: 4px;
  text-align: center;
}

@media (max-width: 768px) {
  .reference-card {
    padding: 1rem;
  }

  .reference-grid {
    grid-template-columns: 1fr;
    max-height: 500px;
  }

  .conversion-card {
    padding: 1rem;
  }

  .conversion-icon {
    font-size: 2rem;
  }

  .tip-tooltip {
    max-width: 160px;
    font-size: 0.8rem;
  }

  .square-grid.cup {
    width: 120px;
    height: 120px;
  }

  .square-grid.pint {
    width: 240px;
    height: 120px;
  }

  .square-grid.quart,
  .square-grid.gallon {
    width: 240px;
    height: 240px;
  }

  .square-cell {
    font-size: 1rem;
  }

  .view-title {
    font-size: 1.5rem;
  }
} 