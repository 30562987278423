.flagged-words-page {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
}

.page-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  background: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.2s ease;
}

.back-button:hover {
  transform: translateX(-2px);
  border-color: var(--primary-color);
}

.page-header h1 {
  color: var(--primary-color);
  font-size: 2rem;
  margin: 0;
}

.practice-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 1.5rem;
  background: var(--card-background);
  border-radius: 12px;
  border: 1px solid var(--border-color);
}

.practice-button {
  padding: 1rem 2rem;
  background: var(--primary-color);
  color: black;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.practice-button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 210, 255, 0.3);
}

.practice-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.flagged-count {
  color: var(--text-secondary);
  font-size: 0.95rem;
}

.flagged-words-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.flagged-word-card {
  position: relative;
  padding: 1.5rem;
  background: var(--card-background);
  border-radius: 12px;
  border: 1px solid var(--border-color);
  margin-bottom: 1.5rem;
  transition: all 0.2s ease;
}

.flagged-word-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px var(--shadow-color);
}

.word-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;
}

.word-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.word-info h2 {
  margin: 0;
  color: var(--text-color);
  font-size: 1.5rem;
}

.part-of-speech {
  color: var(--text-secondary);
  font-style: italic;
  font-size: 0.9rem;
}

.definition {
  color: var(--text-secondary);
  line-height: 1.6;
  margin-bottom: 1.5rem;
  padding: 1rem;
  background: var(--input-background);
  border-radius: 8px;
  border: 1px solid var(--border-color);
}

.synonyms-section h3 {
  color: var(--text-color);
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.synonyms-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.synonym-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.synonym-tag {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.6rem 1rem;
  background: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: 20px;
  color: var(--text-color);
}

.synonym-tag.flagged {
  background: rgba(255, 215, 0, 0.15);
  border-color: #ffd700;
  color: #ffd700;
}

.unflag-button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 0.25rem;
  opacity: 0.7;
  transition: all 0.2s ease;
}

.unflag-button:hover {
  opacity: 1;
  transform: scale(1.1);
}

.pronunciation-bar {
  height: 3px;
  background: var(--border-color);
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  width: 100%;
}

.pronunciation-bar:hover {
  background: var(--primary-color);
}

.pronunciation-bar.playing {
  background: var(--primary-color);
  animation: playProgress 1s linear;
}

.expanded-content {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--border-color);
}

.example-section h3 {
  color: var(--text-color);
  font-size: 1.1rem;
  margin-bottom: 0.75rem;
}

.example-sentence {
  color: var(--text-secondary);
  font-style: italic;
  line-height: 1.6;
  padding: 1rem;
  background: var(--input-background);
  border-radius: 8px;
  border: 1px solid var(--border-color);
}

.no-flagged-words {
  text-align: center;
  padding: 4rem 2rem;
  background: var(--card-background);
  border-radius: 12px;
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.empty-icon {
  font-size: 3rem;
  color: var(--text-secondary);
}

.no-flagged-words h2 {
  color: var(--text-color);
  margin: 0;
}

.no-flagged-words p {
  color: var(--text-secondary);
  margin: 0;
  max-width: 400px;
}

.start-quiz-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background: var(--primary-color);
  border: none;
  border-radius: 8px;
  color: black;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.2s ease;
  margin-top: 1.5rem;
}

.start-quiz-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 210, 255, 0.3);
}

@keyframes playProgress {
  from { transform: scaleX(0); }
  to { transform: scaleX(1); }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .flagged-words-page {
    padding: 1rem;
    margin: 1rem;
  }

  .practice-section {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }

  .practice-button {
    width: 100%;
    justify-content: center;
  }

  .word-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
  }

  .word-info {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .practice-info {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }

  .practice-stats {
    text-align: center;
  }

  .practice-button {
    width: 100%;
    justify-content: center;
  }

  .due-badge {
    position: static;
    display: inline-block;
    margin-bottom: 1rem;
  }
}

.controls-section {
  background: var(--card-background);
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border: 1px solid var(--border-color);
  width: 100%;
  box-sizing: border-box;
}

.search-bar {
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.search-bar input {
  width: 100%;
  padding: 0.75rem 2.5rem;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background: var(--input-background);
  color: var(--text-color);
  font-size: 1rem;
  box-sizing: border-box;
}

.search-icon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-secondary);
}

.clear-search {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 0.25rem;
}

.filter-controls {
  display: flex;
  gap: 1rem;
}

.filter-group {
  flex: 1;
}

.filter-group label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-secondary);
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.filter-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background: var(--input-background);
  color: var(--text-color);
  cursor: pointer;
}

.practice-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.practice-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.practice-stats {
  text-align: right;
  color: var(--text-secondary);
}

.practice-stats p {
  margin: 0.25rem 0;
}

.flagged-word-card {
  position: relative;
}

.due-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 600;
}

.due-badge.overdue {
  background: rgba(255, 68, 68, 0.1);
  color: var(--error-color);
}

.due-badge.today {
  background: rgba(255, 215, 0, 0.1);
  color: #ffd700;
}

.due-badge.thisWeek {
  background: rgba(0, 210, 255, 0.1);
  color: var(--primary-color);
}

.synonyms-section {
  margin-top: 1.5rem;
}

.synonyms-section h3 {
  color: var(--text-color);
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.synonyms-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.synonym-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .filter-controls {
    flex-direction: column;
  }

  .practice-info {
    flex-direction: column;
    text-align: center;
  }

  .practice-stats {
    text-align: center;
  }

  .practice-button {
    width: 100%;
  }
} 