.main-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-header {
  text-align: center;
  margin-bottom: 3rem;
  padding: 2rem;
  background: var(--card-background);
  border-radius: 16px;
  border: 1px solid var(--border-color);
  box-shadow: 0 4px 20px var(--shadow-color);
}

.main-header h1 {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.main-header p {
  color: var(--text-secondary);
  font-size: 1.1rem;
  max-width: 600px;
  margin: 0 auto;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
  margin-bottom: 3rem;
}

.feature-card {
  background: var(--card-background);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  padding: 1.5rem;
  text-decoration: none;
  color: var(--text-color);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.feature-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px var(--shadow-color);
  border-color: var(--primary-color);
}

.feature-icon {
  width: 48px;
  height: 48px;
  background: var(--input-background);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: var(--primary-color);
  transition: all 0.3s ease;
}

.feature-card:hover .feature-icon {
  background: var(--primary-color);
  color: black;
  transform: scale(1.1);
}

.feature-content h2 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: var(--text-color);
}

.feature-content p {
  color: var(--text-secondary);
  font-size: 0.95rem;
  line-height: 1.5;
}

.main-footer {
  margin-top: auto;
  text-align: center;
  padding: 2rem;
  color: var(--text-secondary);
  font-size: 0.9rem;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .main-page {
    padding: 1rem;
  }

  .main-header {
    padding: 1.5rem;
    margin-bottom: 2rem;
  }

  .main-header h1 {
    font-size: 2rem;
  }

  .main-header p {
    font-size: 1rem;
  }

  .features-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .feature-card {
    padding: 1.25rem;
  }
}

/* Dark mode enhancements */
@media (prefers-color-scheme: dark) {
  .feature-card:hover {
    box-shadow: 0 8px 24px rgba(0, 210, 255, 0.15);
  }
}

/* Animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.feature-card {
  animation: fadeInUp 0.5s ease backwards;
}

.feature-card:nth-child(1) { animation-delay: 0.1s; }
.feature-card:nth-child(2) { animation-delay: 0.2s; }
.feature-card:nth-child(3) { animation-delay: 0.3s; }
.feature-card:nth-child(4) { animation-delay: 0.4s; }

.settings-link {
  text-align: center;
  margin-top: 2rem;
}

.settings-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  background: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-color);
  text-decoration: none;
  transition: all 0.2s ease;
}

.settings-button:hover {
  border-color: var(--primary-color);
  transform: translateY(-2px);
} 