.flagged-quiz-page {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
}

.quiz-header {
  background: var(--card-background);
  padding: 1.5rem;
  border-radius: 12px;
  margin-bottom: 2rem;
  border: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quiz-progress {
  color: var(--text-secondary);
  font-size: 1.1rem;
  font-weight: 500;
}

.question-container {
  background: var(--card-background);
  border-radius: 12px;
  padding: 2rem;
  border: 1px solid var(--border-color);
  box-shadow: 0 4px 20px var(--shadow-color);
}

.word {
  color: var(--primary-color);
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
}

.choices {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.choice-button {
  background: var(--input-background);
  border: 1px solid var(--border-color);
  padding: 1.25rem;
  border-radius: 12px;
  font-size: 1.1rem;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.2s ease;
  min-height: 70px;
}

.choice-button:hover {
  transform: translateY(-2px);
  border-color: var(--primary-color);
  background: rgba(0, 210, 255, 0.1);
}

.answer-section {
  animation: fadeIn 0.3s ease;
}

.answer-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.definition {
  padding: 1.25rem;
  background: var(--input-background);
  border-radius: 8px;
  border: 1px solid var(--border-color);
  color: var(--text-color);
  line-height: 1.6;
  text-align: center;
}

.correct-answer {
  text-align: center;
  color: var(--text-secondary);
  font-size: 1.1rem;
}

.correct-answer span {
  color: var(--secondary-color);
  font-weight: 600;
}

.confidence-buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-top: 1rem;
}

.confidence-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  background: var(--button-bg);
  border: 1px solid var(--button-color);
  border-radius: 12px;
  color: var(--button-color);
  cursor: pointer;
  transition: all 0.2s ease;
}

.confidence-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px var(--button-bg);
}

.confidence-icon {
  font-size: 1.5rem;
}

.interval {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.9rem;
  opacity: 0.8;
}

.no-questions {
  text-align: center;
  padding: 4rem 2rem;
  background: var(--card-background);
  border-radius: 12px;
  border: 1px solid var(--border-color);
}

.no-questions h2 {
  color: var(--text-color);
  margin-bottom: 1rem;
}

.no-questions p {
  color: var(--text-secondary);
  margin-bottom: 2rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .flagged-quiz-page {
    padding: 1rem;
    margin: 1rem;
  }

  .question-container {
    padding: 1.5rem;
  }

  .word {
    font-size: 2rem;
  }

  .choices {
    grid-template-columns: 1fr;
  }

  .confidence-buttons {
    grid-template-columns: 1fr;
  }
} 