.quiz-results-page {
  max-width: 800px;
  margin: 2rem auto;
  padding: 1.5rem;
}

.header-left {
  margin-bottom: 2rem;
}

.back-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  background: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-color);
  text-decoration: none;
  font-weight: 500;
  transition: all 0.2s ease;
}

.back-button:hover {
  transform: translateX(-2px);
  border-color: var(--primary-color);
  box-shadow: 0 4px 12px rgba(0, 210, 255, 0.15);
}

.results-container {
  background: var(--card-background);
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 20px var(--shadow-color);
  border: 1px solid var(--border-color);
}

.results-title {
  color: var(--primary-color);
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.results-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.result-card {
  background: var(--input-background);
  border-radius: 12px;
  padding: 1.5rem;
  border: 1px solid var(--border-color);
  transition: all 0.2s ease;
}

.result-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.result-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.word-title {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 1.5rem;
  color: var(--text-color);
  margin: 0;
  font-weight: 600;
}

.word-emoji {
  font-size: 1.4rem;
}

.score-badge {
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-weight: 600;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.word-definition {
  color: var(--text-secondary);
  line-height: 1.6;
  margin: 1rem 0;
  padding: 1rem;
  background: var(--card-background);
  border-radius: 8px;
  border: 1px solid var(--border-color);
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
}

.definition-emoji {
  font-size: 1.1rem;
  margin-top: 0.2rem;
}

.synonyms-section {
  margin: 1.5rem 0;
}

.synonyms-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-color);
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.synonyms-icon {
  font-size: 1.2rem;
}

.synonyms-container {
  padding: 1rem;
  background: var(--card-background);
  border-radius: 8px;
  border: 1px solid var(--border-color);
}

.synonyms-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.synonym-wrapper {
  flex: 0 0 auto;
}

.synonym-tag {
  display: inline-flex;
  align-items: center;
  padding: 0.6rem 1.2rem;
  border-radius: 20px;
  font-size: 0.95rem;
  gap: 0.5rem;
  background: var(--input-background);
  border: 1px solid var(--border-color);
  color: var(--text-color);
  transition: all 0.2s ease;
}

.synonym-tag:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.synonym-tag.correct {
  background: rgba(74, 222, 128, 0.1);
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}

.synonym-tag.incorrect {
  background: rgba(255, 68, 68, 0.1);
  border-color: var(--error-color);
  color: var(--error-color);
}

.status-icon {
  font-size: 0.9rem;
}

.time-info {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  background: var(--card-background);
  border-radius: 8px;
  border: 1px solid var(--border-color);
  color: var(--text-secondary);
  font-size: 0.95rem;
}

.time-emoji {
  font-size: 1.1rem;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .quiz-results-page {
    margin: 0;
    padding: 1rem;
  }

  .results-container {
    padding: 1.25rem;
  }

  .results-title {
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
  }

  .result-card {
    padding: 1.25rem;
  }

  .word-title {
    font-size: 1.3rem;
  }

  .word-definition {
    padding: 0.875rem;
    font-size: 0.95rem;
  }

  .synonyms-container {
    padding: 0.875rem;
  }

  .synonym-tag {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }

  .time-info {
    padding: 0.625rem 0.875rem;
    font-size: 0.9rem;
  }
}

/* Small screen optimizations */
@media (max-width: 380px) {
  .results-container {
    padding: 1rem;
  }

  .result-card {
    padding: 1rem;
  }

  .word-title {
    font-size: 1.2rem;
  }

  .score-badge {
    padding: 0.4rem 0.875rem;
    font-size: 0.85rem;
  }
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.result-card {
  animation: fadeIn 0.3s ease;
  animation-fill-mode: both;
}

.result-card:nth-child(2) { animation-delay: 0.1s; }
.result-card:nth-child(3) { animation-delay: 0.2s; }
.result-card:nth-child(4) { animation-delay: 0.3s; }
.result-card:nth-child(5) { animation-delay: 0.4s; }

.synonym-tag.flagged {
  background: rgba(255, 215, 0, 0.15);
  border-color: #ffd700;
  color: #ffd700;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0.4);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(255, 215, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0);
  }
}

.synonym-tag.correct.flagged {
  background: rgba(255, 215, 0, 0.15);
  border-color: #ffd700;
  color: #ffd700;
}

.overall-stats {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
  padding: 1rem;
  background: var(--input-background);
  border-radius: 12px;
  border: 1px solid var(--border-color);
}

.stat-card {
  text-align: center;
  padding: 1.25rem;
  background: var(--card-background);
  border-radius: 8px;
  border: 1px solid var(--border-color);
  transition: all 0.2s ease;
}

.stat-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px var(--shadow-color);
}

.stat-icon {
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 0.75rem;
}

.stat-icon.score {
  background: rgba(0, 210, 255, 0.1);
  color: var(--primary-color);
}

.stat-icon.correct {
  background: rgba(74, 222, 128, 0.1);
  color: var(--secondary-color);
}

.stat-icon.time {
  background: rgba(255, 215, 0, 0.1);
  color: #ffd700;
}

.stat-icon.flagged {
  background: rgba(255, 68, 68, 0.1);
  color: var(--error-color);
}

.stat-value {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--text-color);
  margin-bottom: 0.25rem;
}

.stat-label {
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.word-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.part-of-speech {
  color: var(--text-secondary);
  font-style: italic;
  font-size: 0.9rem;
  padding: 0.25rem 0.75rem;
  background: var(--input-background);
  border-radius: 20px;
  border: 1px solid var(--border-color);
}

.score-badges {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.score-badge {
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-weight: 600;
  font-size: 0.9rem;
}

.score-badge.good {
  background: rgba(74, 222, 128, 0.1);
  color: var(--secondary-color);
}

.score-badge.poor {
  background: rgba(255, 68, 68, 0.1);
  color: var(--error-color);
}

.time-badge {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  background: var(--input-background);
  border: 1px solid var(--border-color);
  color: var(--text-secondary);
  font-size: 0.9rem;
}

/* Update mobile responsiveness */
@media (max-width: 768px) {
  .overall-stats {
    grid-template-columns: repeat(2, 1fr);
  }

  .stat-card {
    padding: 1rem;
  }

  .stat-value {
    font-size: 1.25rem;
  }

  .score-badges {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
}

@media (max-width: 480px) {
  .overall-stats {
    grid-template-columns: 1fr;
  }
}