.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.progress-modal {
  background-color: var(--card-background);
  border-radius: 12px;
  padding: 2rem;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  animation: modalSlideIn 0.3s ease;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.modal-header h2 {
  color: var(--text-color);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
}

.modal-icon {
  font-size: 1.4rem;
}

.close-button {
  background: none;
  border: none;
  color: var(--text-secondary);
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.close-button:hover {
  background-color: var(--input-background);
  color: var(--text-color);
}

.modal-description {
  color: var(--text-secondary);
  margin-bottom: 2rem;
  font-size: 0.9rem;
}

.progress-grid {
  display: grid;
  gap: 1.5rem;
}

.progress-item {
  background-color: var(--input-background);
  border-radius: 8px;
  padding: 1.25rem;
  border: 1px solid var(--border-color);
}

.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.category-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-color);
  font-weight: 500;
}

.category-icon {
  font-size: 1.2rem;
}

.progress-value {
  color: var(--primary-color);
  font-weight: 600;
}

.progress-bar-container {
  width: 100%;
  height: 8px;
  background-color: var(--card-background);
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

.progress-bar {
  height: 100%;
  transition: width 0.3s ease, background-color 0.3s ease;
}

.mastery-level {
  color: var(--text-secondary);
  font-size: 0.9rem;
  text-align: right;
}

@keyframes modalSlideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 480px) {
  .progress-modal {
    padding: 1.5rem;
    width: 95%;
  }

  .progress-item {
    padding: 1rem;
  }
} 